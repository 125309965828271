<template>
  <button class="v-button-mts" :type="type" v-on="$listeners">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'VButtonMTS',
  props: {
    type: {
      type: String,
      default: 'button',
    },
  },
}
</script>

<style lang="scss" scoped>
.v-button-mts {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  border: none;
  background: transparent;
  color: $color-dark-2;
  text-align: center;
  font-family: 'MTS Compact', sans-serif;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 1;

  &.--red {
    background: #ff0032;
    color: $color-white;
  }

  &.--white {
    background: $color-white;

    &:hover {
      background: #f2f3f7;
    }

    &:active {
      background: #e2e5eb;
    }
  }

  &.--violet {
    background: #8743dd;
    color: $color-white;
  }

  &.--violet-gradient {
    color: $color-white;
    position: relative;
    background: transparent;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 300%;
      height: 300%;
      transition: all 0.3s ease;
      background: linear-gradient(
        -123.59deg,
        #5f1bb5 0%,
        #632fd3 19.688%,
        #732fc9 39.376%,
        #7743e7 59.064%,
        #8743dd 78.752%,
        #8b57fb 98.44%
      );
      transform: translate(0%, 0%);
      z-index: -1;
    }

    &:hover {
      &::before {
        transform: translate(-25%, -25%);
      }
    }

    &:active {
      &::before {
        transform: translate(-50%, -50%);
      }
    }
  }

  &.--dark-black {
    background: #0A0311;
    color: $color-white;

    &:hover {
      background: #2C3135;
    }

    &:active {
      background: #000000;
    }
  }

  &.--black {
    background: $color-dark-2;
    color: $color-white;

    &:hover {
      background: #2c3135;
    }

    &:active {
      background: #000000;
    }
  }

  &.--disabled {
    background: #bcc3d080;
    color: #969fa8;
  }

  &.--xl {
    font-size: 20px;
    line-height: 24px;
    border-radius: 12px;
    padding: 24px;
  }

  &.--l {
    font-size: 17px;
    line-height: 24px;
    border-radius: 8px;
    padding: 14px;
  }

  &.--m {
    font-size: 17px;
    line-height: 24px;
    border-radius: 8px;
    padding: 10px;
  }

  &.--s {
    font-size: 14px;
    line-height: 20px;
    border-radius: 6px;
    padding: 8px;
  }
}
</style>
