export default {
  data() {
    return {
      formStatus: 'invalid',
      isLoading: false,
      titleMessage: '',
      textMessage: '',
      success: '',
      statusForm: false,
      statusFormText: '',
      canRetry: false,
      isShowForm: true,
      isShowMessage: false,
      idFormOnResult: '',
    }
  },
  methods: {
    __onFormResult(params) {
      if (params.status) {
        this.titleMessage = params.title
        this.textMessage = params.message
        this.success = params.success
        this.statusForm = true
        this.statusFormText = params.status
        this.canRetry = params.retry
        this.idFormOnResult = params.id
      }
    },
    onSubmit(event) {
      event.preventDefault()
      this.$v.$touch()
      this.$emit('validate')
      if (this.$v.$invalid) return

      this.formStatus = 'valid'
      this.isLoading = true

      if (typeof Gateway.Client !== 'undefined') {
        const gateway = new Gateway.Client()

        gateway.sendForm(event.currentTarget, (result) => {
          /* Ошибки от валидаций шлюза */
          const fieldsWithErrors =
            result?.platform_response?.response?.errors ?? {}

          /* Ошибки от платформы */
          const fieldErrors =
            result?.platform_response?.response?.error?.fieldErrors

          if (fieldErrors) {
            const platformFieldsMap = {
              name: 'firstname',
              secondName: 'lastname',
              email: 'email',
              password: 'password',
            }
            for (const field in fieldErrors) {
              if (field in platformFieldsMap) {
                fieldsWithErrors[platformFieldsMap[field]] = fieldErrors[field]
              }
            }
          }

          if (Object.keys(fieldsWithErrors).length > 0) {
            this.$eventBus.$emit('form-server-errors', {
              invalidFields: fieldsWithErrors,
            })

            this.formStatus = 'invalid'
            this.isLoading = false
          } else {
            this.$eventBus.$emit('form-result', {
              title: result.title,
              message: result.message,
              success: result.success,
              status: result.status,
              retry: result.retry,
              id: result.form.id,
            })

            this.isLoading = false
            this.isShowForm = false
            this.isShowMessage = true
          }
        })
      } else {
        this.isLoading = false
        console.error('GatewayClient is not ready')
      }
    },
  },
}
