<template>
  <component :is="pageComponent"/>
</template>

<script>

import {logToConsole} from "../helpers/common";
import ErrorPageDesktop from "~/components/Page/ErrorPage/ErrorPageDesktop.vue";
import ErrorPageMobile from "~/components/Page/ErrorPage/ErrorPageMobile.vue";

export default {
  layout: (ctx) => {
    if (ctx.$device.isDesktop) {
      return 'primary-desktop';
    } else {
      return 'primary-mobile';
    }
  },
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  computed: {
    pageComponent() {
      return this.$device.isDesktop ? ErrorPageDesktop : ErrorPageMobile;
    }
  },
  mounted() {
    logToConsole(this.error.message);
  },
};
</script>
