// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/content-page-promo-bg.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".promo[data-v-7df367fa]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");padding-bottom:87px}.error-page__section[data-v-7df367fa]{background:#f5f5f5;border-bottom:10px solid #fff;padding:115px 0}.error-page__section .container[data-v-7df367fa]{max-width:670px}.error-page__number[data-v-7df367fa]{margin:0 auto;max-width:1000px;font-size:420px;font-weight:700;color:#fff;opacity:.8;text-align:center}.error-page__title[data-v-7df367fa]{font-size:42px;line-height:1.24;font-weight:300;font-family:\"AktivGroteskCorp\",\"Arial\",sans-serif;margin:0 0 28px}@media(max-width:991px){.error-page__title[data-v-7df367fa]{font-size:30px}}.error-page__description[data-v-7df367fa]{font-family:\"AktivGroteskCorp\",\"Arial\",sans-serif;font-size:18px;line-height:1.45;margin-bottom:32px;font-weight:300}.error-page__dop-links[data-v-7df367fa]{margin:0;padding:0;list-style:none;display:flex;flex-wrap:wrap}.error-page__item[data-v-7df367fa]{font-family:\"AktivGroteskCorp\",\"Arial\",sans-serif;position:relative;font-size:19px;line-height:1.6;font-weight:300;width:33%;margin-bottom:23px;padding-left:30px}@media(max-width:991px){.error-page__item[data-v-7df367fa]{width:45%;margin:0 0 51px 9%}}@media(max-width:991px){.error-page__item[data-v-7df367fa]:nth-child(odd){margin-left:0}}.error-page__item[data-v-7df367fa]:before{content:\"\";position:absolute;left:0;top:0;bottom:0;margin:auto;width:18px;height:1px;background:#111}.error-page__item a[data-v-7df367fa]{text-decoration:none;border-bottom:1px solid rgba(0,0,0,.1);transition:.2s;color:#111}.error-page__item a[data-v-7df367fa]:focus,.error-page__item a[data-v-7df367fa]:hover{border-bottom-color:#111}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
