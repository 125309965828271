<template>
  <div class="order-form order-platform-form">
    <div class="inner-width order-form-inner">
      <div class="order-form__title">Попробуйте сервисы МТС Линк бесплатно</div>

      <div class="order-form__description">
        Зарегистрируйтесь сейчас и&nbsp;протестируйте премиум-функционал бесплатно.
        Базовый тариф со встречами на 30 человек останется у вас бесплатно и&nbsp;навсегда.
      </div>

      <div class="order-form__content">
        <WebinarRegistrationForm :modifier="'clients'" :is-a-b-test-enabled="isABTestEnabled" />
      </div>
    </div>
  </div>
</template>

<script>

import WebinarRegistrationForm from "~/components/Common/Form/WebinarRegistrationForm.vue";

export default {
  name: 'TryNewPlatformForm',
  components: {WebinarRegistrationForm},
  props: {
    isABTestEnabled: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="scss" scoped>
.order-form {
  background: #673ab7;
  padding: 92px 0 125px;
  color: #fff;

  &-inner {
    position: relative;
  }

  &__title {
    font-size: 48px;
    line-height: 1.24;
    text-align: center;
    margin: 0 auto 34px;
    max-width: 950px;
    font-weight: 300;

    @media (max-width: 767px) {
      font-size: 42px;
    }

    @media (max-width: 560px) {
      font-size: 30px;
    }
  }

  &__description {
    line-height: 1.6;
    text-align: center;
    margin: 0 auto 28px;
    font-size: 16px;
    max-width: 620px;
    font-weight: 300;
  }

  &__content {
    position: relative;
    max-width: 510px;
    margin: 0 auto;
  }
  ::v-deep .form-control {
    &__select{
      &:after {
        display: none;
      }
      &-label {
        transform: translateY(0);
      }
      &-body {
        border: 1px solid;
        appearance: auto;
        padding: 0;
        margin-top: 7px;
      }
    }
  }
}

</style>
