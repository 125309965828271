<template>
  <header class="header-demo">
    <a href="/" class="header-demo__logo-link">
      <img
        class="header-demo__logo"
        src="~/assets/img/main/logo--mts-icon-full.svg"
        alt="logo"
        width="68"
        height="26"
      />
    </a>
  </header>
</template>

<script>
export default {
  name: 'MTSHeaderDemoMobile',
}
</script>

<style lang="scss" scoped>
.header-demo {
  text-align: center;
  margin-bottom: 12px;
}
</style>
