<template>
  <div class="form-control" :class="[modifier.join(' '), { 'is-descr': descr }]">
    <input v-bind="$attrs" class="form-control__input-text" :class="{ 'show-label': labelModifier }" :type="type"
      @focus="focus" @blur="blur" @input="updateValue" />
    <label :for="$attrs.id" class="form-control__label">
      {{ $attrs.label }}
      <span v-if="descr" class="form-control__descr">
        {{ descr }}
      </span>
    </label>
    <div v-if="isPassword" class="form-control__show-password" @click="togglePasswordShow()">
      <img alt="show password" title="Показать пароль" width="25" height="25" src="/img/eye.svg">
    </div>

    <div v-if="mutatableError" class="form-control__error" :class="modifier.join(' ')">{{ mutatableError }}</div>
  </div>
</template>

<script>
// modifier:dark-font - темный шрифт для светлых форм (по умолчанию цвет шрифта белый)
// modifier:sidebar-mode - для форм, которые в сайдбаре
export default {
  name: 'VInput',
  inheritAttrs: false,
  props: {
    descr: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    modifier: {
      type: Array,
      default: () => [],
    },
    isPassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mutatableError: null,
      type: null,
    };
  },
  computed: {
    labelModifier() {
      return !this.$attrs.value.length;
    },
  },
  created() {
    this.$parent.$on('validate', this.setError);
  },
  mounted() {
    this.type = this.$attrs?.type
  },
  destroyed() {
    this.$parent.$off('validate', this.setError);
  },
  methods: {
    setError() {
      this.$nextTick(() => {
        this.mutatableError = this.error;
      })
    },

    setValue(value) {
      this.$emit('input', value);
    },

    updateValue(event) {
      this.setValue(event.target.value);
    },

    focus() {
      this.mutatableError = null;
    },

    blur() {
      this.$emit('blur');
    },

    togglePasswordShow() {
      this.type = this.type === 'text' ? this.$attrs?.type : 'text';
    }
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  display: flex;
  flex-direction: column;
  min-height: 58px;
  position: relative;

  &.is-descr {
    min-height: 76px;
  }

  &__input-text {
    display: inline-block;
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background: none;
    border-radius: 0;
    box-shadow: none;
    padding: 0 0 6px 0;
    font-weight: 400;
    font-size: 16px;
    color: $color-white;
    opacity: 0.8;
    z-index: 1;
    outline: none;
    resize: none;
    order: 1;

    &:focus {
      ~.form-control__label {
        transform: translateY(0px);
        font-size: 13px;
        opacity: 0.8;
      }
    }

    &.show-label:not(:focus):valid~.form-control__label {
      opacity: 0.8;
    }

    &:-webkit-autofill:not(:focus):valid {
      &~.form-control__label {
        opacity: 0;
      }
    }
  }

  &__label {
    display: inline-block;
    width: 100%;
    order: 0;
    font-weight: 300;
    font-size: 15px;
    color: $color-white;
    text-align: left;
    transform: translateY(17px);
    -webkit-transition: all cubic-bezier(0.25, 0.8, 0.25, 1) 0.25s;
    transition: all cubic-bezier(0.25, 0.8, 0.25, 1) 0.25s;
    opacity: 0;
  }

  &__descr {
    font-family: "Roboto", sans-serif;
    display: inline-block;
    width: 100%;
    color: $color-white;
    text-align: left;
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    opacity: 0.4;
  }

  &__error {
    font-family: "Roboto", sans-serif;
    order: 2;
    padding-top: 5px;
    color: #ff8787;
    text-align: left;
    font-weight: 300;
    font-size: 13px;
    transform: translate(0px);
    transition: all cubic-bezier(0.25, 0.8, 0.25, 1) 0.25s;
  }

  &__show-password {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    filter: invert(98%) sepia(9%) saturate(0%) hue-rotate(167deg) brightness(86%) contrast(84%);
    cursor: pointer;
    height: 25px;
    z-index: 1000;
  }
}
</style>
