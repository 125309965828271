<template>
  <FormSidebar
    :form="form"
    :header="header"
    :is-a-b-test-enabled="isABTestEnabled"
    :form-name="formName"
    @closeFormSidebar="closeFormSidebar"
  />
</template>
<script>
const FormSidebar = () => import(
    /* webpackChunkName: "FormSidebar" */
    '~/components/Common/Form/FormSidebar.vue'
  );
export default {
  name: 'LazyFormSidebar',
  components: {
    FormSidebar,
  },
  props: {
    form: {
      type: String,
    },
    header: {
      type: String,
    },
    isABTestEnabled: {
      type: Boolean,
      default: false,
    },
    formName: {
      type: String,
      default: undefined,
    }
  },
  methods: {
    closeFormSidebar() {
      this.$emit('closeFormSidebar');
    },
  },
}
</script>
