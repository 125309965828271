<template>
  <div>
  </div>
</template>
<script>

export default {
  name: "ExternalIntegrations",
  components: {},
  head() {
    return {
      script: [
        {
          hid: 'mtsFormsHandler',
          id: 'mtsFormsHandler',
          async: true,
          src: `${this.$config.gatewayURL}/js/client/website-handler.js?t=${Math.floor(new Date().getTime()/600000)}`,
        },
        {
          hid: 'mtsAnalytics',
          id: 'mtsAnalytics',
          async: true,
          src: `${this.$config.gatewayURL}/js/client/mts-analytics.js?t=${Math.floor(new Date().getTime()/600000)}`,
        },
      ],
      __dangerouslyDisableSanitizersByTagID: {
        'wrAnalyticsOptions': ['innerHTML'],
      }
    }
  },
}
</script>

<!-- 15.08.2024
Убрали со всех страниц. В случае если нужно вернуть, данный компонент нужно импортировать и добавить в layout -->
