<template>
  <svg-icon :name="name"/>
</template>

<script>
export default {
  name: 'VIconMTS',
  props: {
    name: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="scss"></style>
