<template>
  <div class="header-menu">
    <button class="header-menu__burger-btn" data-toggle="slideout-button" aria-label="Aria Open menu"
      @click="$emit('openSidebar')">
      <VIconMTS :name="'mts/icon-mts--menu'" width="24" height="24" fill="#969FA8" />
    </button>
  </div>
</template>

<script>
import VIconMTS from '~/components/Common/MTS/VIconMTS.vue'
export default {
  name: 'HeaderMenuListMobile',
  components: { VIconMTS }
}
</script>

<style lang="scss" scoped>
.header-menu {
  &__burger-btn {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    width: 36px;
    height: 36px;
    background: none;
    border: none;
    position: relative;
    cursor: pointer;
  }
}
</style>
