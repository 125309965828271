export const EMPTY_FIELD_ERROR_MESSAGE = 'Обязательное поле';
export const MAX_LETTERS_NAME = 50;
export const MAX_LETTERS_EMAIL = 190;
export const MAX_LETTERS_ORG = 255;
export const MIN_LETTERS_ORG = 8;

export const whiteSpaceInValue = (value) => {
  return !/\s/.test(value);
}

// проверяет телефон со скобками (русские и беларуские номера)
const PHONE_REGEX = /(^(\+7|7|8)?[\s\-]?\([0-9]{3}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$)|(^(\+3|3)[0-9]{2}?[\s\-]?\([0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$)/;

// проверяет телефон со скобками (русские номера)
// const PHONE_REGEX = /(^(\+7|7|8)?[\s-]?\([0-9]{3}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$)|(^\+3[0-9]{2}?[\s-]?\([0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$)/;

// проверяет телефон без скобок
// const PHONE_REGEX = /(^(\+7|7|8)?[\s-]?[0-9]{3}?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$)|(^\+3[0-9]{2}?[\s-]?\([0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$)/;


export const isPhone = (value) => {
  return PHONE_REGEX.test(value)
}
