<template>
  <div id="data-collection-wrap">
    <div id="data-collection-warning" class="data-collection-warning">
      <div class="data-collection-warning__inner">
        <div class="data-collection-warning__text">
          Продолжая использовать этот сайт, Вы подтверждаете, что ознакомлены и принимаете условия документа
          Политика обработки персональных данных",
          содержащего, в частности, порядок использования файлов cookie.
          <a href="/legal/privacy-policy-soft.pdf" target=_blank>Прочитать&nbsp;об&nbsp;этом</a>
        </div>
        <button class="data-collection-warning__button btn-purple btn">Ок</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DataCollection',
}
</script>
<style lang="scss" scoped>
.data-collection-warning {
  position: fixed;
  bottom: 0;
  background: #000;
  width: 100%;
  z-index: 10000;
  display: none;

  &.active {
    display: block;
  }

  &__inner {
    max-width: 800px;
    margin: 25px auto 20px;
    font-size: 13px;
    color: #aaa;
    line-height: 17px;
    padding: 0 20px;

    @media (min-width: 580px) {
      padding: 0 60px;
      display: flex;
      justify-content: space-between;
    }

    a {
      color: #eee;
    }
  }

  &__text {
    width: 100%;
  }

  &__button {
    min-width: 100px;
    max-width: 100px;
    min-height: 50px;
    max-height: 50px;
    margin: 15px auto 0;

    @media (min-width: 580px) {
      margin-top: 0;
      margin-left: 15px;
    }
  }
}
</style>
