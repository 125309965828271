<template>
  <div class="dropdown">
    <slot name="toggle">
      <div class="dropdown__btn">{{ toggleText }}</div>
    </slot>
    <div class="dropdown__content" :style="{ left: position + 'px' }">
      <ul class="dropdown__content_list">
        <li v-for="(link, idx) in listLink" :key="idx" class="dropdown__content_list-item">
          <slot name="content" :link-content="link">
            <a :href="link.href" class="dropdown__content_list-link">{{ link.title }}</a>
          </slot>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VDropdownList',
  props: {
    listLink: {
      type: Array,
      default: () => [],
    },
    toggleText: {
      type: String,
      default: '',
    },
    position: {
      type: Number,
      default: 0
    }
  },
}
</script>
<style lang="scss" scoped>
.dropdown {
  $block: &;
  position: relative;
  display: inline-block;

  @include hover-focus-active {
    #{$block}__content {
      opacity: 1;
      visibility: visible;
    }
  }

  &__content {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 100%;
    transform-origin: center;
    z-index: 150;
    transition: all 0.3s ease;

    &_list {
      position: relative;
      background-color: #fff;
      margin: 0;
      padding: 0;
      list-style: none;

      &-item {
        margin: 0 0 12px;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          color: #FF0032;
        }
      }

      &-link {
        display: block;
        transition: all 0.3s ease;
        text-decoration: none;
        color: inherit;
      }
    }

  }

  &.--xl {
    #{$block}__content {
      padding-top: 36px;

      &_list {
        padding: 40px;
        border-radius: 24px;
        width: 266px;
      }
    }
  }

  &.--s {
    #{$block}__content {
      padding-top: 12px;

      &_list {
        padding: 12px;
        border-radius: 6px;
      }
    }
  }
}
</style>
