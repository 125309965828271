<template>
  <a class="v-button-link-mts" v-on="$listeners">
    <slot>
    </slot>
  </a>
</template>
<script>
export default {
  name: 'VButtonLinkMTS',
}

</script>
<style lang="scss" scoped>
.v-button-link-mts {
  display: inline-block;
  width: auto;
  border: none;
  text-decoration: none;
  font-family: 'MTS Compact', sans-serif;
  font-weight: 500;
  color: $color-dark-2;
  background: transparent;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  z-index: 1;

  &.--white {
    background: $color-white;

    &:hover {
      background: #F2F3F7;
    }

    &:active {
      background: #E2E5EB;
    }
  }

  &.--grey {
    background: #F2F3F7;

    &:hover {
      background: #E2E5EB;
    }

    &:active {
      background: #F2F3F7;
    }
  }

  &.--violet-gradient {
    color: $color-white;
    position: relative;
    background: transparent;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 300%;
      height: 300%;
      transition: all 0.3s ease;
      background: linear-gradient(-123.59deg, #5F1BB5 0%, #632FD3 19.688%, #732FC9 39.376%, #7743E7 59.064%, #8743DD 78.752%, #8B57FB 98.44% );
      transform: translate(0%, 0%);
      z-index: -1;
    }

    &:hover {
      &::before {
        transform: translate(-25%, -25%);
      }
    }

    &:active {
      &::before {
        transform: translate(-50%, -50%);
      }
    }
  }
  &.--violet {
    background: #8743dd;
    color: $color-white;
  }

  &.--black {
    background: $color-dark-2;
    color: $color-white;

    &:hover {
      background: #2C3135;
    }

    &:active {
      background: #000000;
    }
  }

  &.--xl {
    font-size: 20px;
    line-height: 24px;
    border-radius: 12px;
    padding: 24px;
  }

  &.--l {
    font-size: 17px;
    line-height: 24px;
    border-radius: 8px;
    padding: 14px;
  }

  &.--m {
    font-size: 17px;
    line-height: 24px;
    border-radius: 8px;
    padding: 10px;
  }

  &.--s {
    font-size: 14px;
    line-height: 20px;
    border-radius: 6px;
    padding: 8px;
  }
}
</style>
