import Cookies from "js-cookie"
import consola from "consola";

const noindexPages = [
  '/payment/fail/',
  '/payment/success/',
  '/bill/success/',
  '/bill/',
  '/bill/fail/',
  '/general/'
]

const PageData = {

  checkCollectionMessage: function () {
    const host = location.host;
    const _this = this;
    const message = document.querySelector('#data-collection-wrap');

    if (!message) {
      return;
    }

    this.toggleCollectionVisibility(message);

    function scrollListener() {
      return  _this.toggleCollectionVisibility(message);
    }
    const badge = document.querySelector('.v-badge-mts');

    window.addEventListener('scroll' , scrollListener);
    document.querySelector('.data-collection-warning__button').addEventListener('click', () => {
      window.removeEventListener('scroll' , scrollListener);
      message.querySelector('.data-collection-warning').classList.remove('active');
      if (badge) {
        badge.style.bottom = 40 + 'px';
      }
      Cookies.set('__wpc', 1, {expires: 365 * 20, domain: host});
    })
  },

  toggleCollectionVisibility: function (message) {
    if (typeof Cookies.get('__wpc') !== 'undefined') {
      return;
    }

    const scroll = window.scrollY + window.innerHeight
    const pos =  2000;

    const badge = document.querySelector('.v-badge-mts');
    const dataWarningHeight = document.querySelector('.data-collection-warning').offsetHeight;

    if (scroll < pos) {
      message.querySelector('.data-collection-warning').classList.remove('active');
      if (badge) {
        badge.style.bottom = 40 + 'px';
      }
    } else {
      message.querySelector('.data-collection-warning').classList.add('active');
      if (badge) {
        badge.style.bottom = dataWarningHeight + 10 + 'px';
      }
    }
  },
};

export const initPageData = () => {
  PageData.checkCollectionMessage();
}

export const isNoindexNofollow = (url) => {
  return (process.env.NODE_ENV === 'development' || noindexPages.includes(url));
}
