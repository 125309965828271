<template>
  <header class="header" >
    <HeaderBannerMobile v-if="headerBannerForProductsMessenger" />

    <div class="container header__container">
      <div class="header__inner">
        <a href="/" class="header__item_logo">
          <img
            src="~/assets/img/main/logo--mts.svg"
            alt="Webinar"
            class="header__item_logo-img"
            width="104"
            height="40"
          />
        </a>
        <VButtonLinkMTS
          href="javascript:void(0);"
          class="header__item_btn --s --grey"
          @click="sendMetricsSigninClient"
          >Войти</VButtonLinkMTS
        >
        <HeaderMenuListMobile
          @openSidebar="$emit('openSidebar')"
        />
      </div>
    </div>
  </header>
</template>

<script>
import VButtonLinkMTS from '~/components/Common/MTS/VButtonLinkMTS'
import HeaderMixin from '~/components/Desktop/Header/mixins/HeaderMixin'
import HeaderMenuListMobile from '~/components/Mobile/Header/HeaderMenuListMobile.vue'
import HeaderBannerMobile from "~/components/Mobile/Header/HeaderBannerMobile.vue";

export default {
  name: 'HeaderMobile',
  components: { HeaderMenuListMobile, VButtonLinkMTS, HeaderBannerMobile },
  mixins: [HeaderMixin],
}
</script>

<style lang="scss" scoped>
.header {
  background: #fff;
  border-bottom: 1px solid rgba(150, 159, 168, 0.2);
  position: relative;
  font-family: 'MTS Compact', 'Arial', sans-serif;
  z-index: 450;

  .container {
    padding: 8px 20px;
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  &__item {
    &_logo {
      display: flex;
    }
    &_btn {
      margin: 0 16px 0 auto;
    }
  }
}
</style>
