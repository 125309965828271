<template>
  <button class="v-button" :class="color" :type="type" v-on="$listeners">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'VButton',
  props: {
    color: {
      type: String,
      default: 'white',
    },
    type: {
      type: String,
      default: 'button',
    },
  },
};
</script>

<style lang="scss" scoped>
.v-button {
  display: inline-block;
  text-decoration: none;
  border: none;
  cursor: pointer;
  height: 50px;
  line-height: 20px;
  font-size: 13px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  border-radius: 25px;
  color: #fff;
  padding: 0 25px;
  background: #673ab7;
  font-weight: 400;
  text-align: center;
  -webkit-transition: .2s;
  transition: .2s;
  font-family: AktivGroteskCorp, sans-serif;

  &.white {
    background: #fff;
    color: #000a18;
  }

  &.orange {
    background: #ffb300;
    color: #111;
  }

  &.purple {
    background: #673ab7;
    color: #fff;
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>
