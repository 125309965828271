// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/arrow-select.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-control[data-v-7f471fde]{display:flex;flex-direction:column;min-height:66px}.form-control.sidebar-mode.hide-label .form-control__select-label[data-v-7f471fde]{transform:translateY(0);font-size:12px;line-height:12px;color:#919191}.form-control__select[data-v-7f471fde]{display:inline-block;border-bottom:1px solid hsla(0,0%,100%,.2);position:relative}.form-control__select[data-v-7f471fde]:after{content:\"\";position:absolute;top:75%;right:0;transform:translateY(-75%);border:4px solid transparent;border-top-color:#fff;opacity:.8}.form-control__select-label[data-v-7f471fde]{display:inline-block;font-family:Roboto,sans-serif;font-weight:300;font-size:14px;color:#fff;transform:translateY(17px);transition:all .25s cubic-bezier(.25,.8,.25,1);opacity:.8}.form-control__select-body[data-v-7f471fde]{font-size:12px;display:block;position:relative;padding-bottom:6px;border:none;outline:none;font-family:\"AktivGroteskCorp\",\"Arial\",sans-serif;-webkit-appearance:none;-moz-appearance:none;appearance:none;z-index:10;cursor:pointer}.sidebar-mode .form-control__select-body[data-v-7f471fde]{font-weight:400;font-size:14px;width:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat right 22px transparent;color:hsla(0,0%,100%,.87)}.sidebar-mode .form-control__select-body option[data-v-7f471fde]{background:#000;cursor:pointer}.sidebar-mode .form-control__select-body option[data-v-7f471fde]:checked{background-color:#673ab7}.form-control__descr[data-v-7f471fde]{font-size:12px;color:#fff;opacity:.4;text-align:left;line-height:16px}.form-control__descr[data-v-7f471fde],.form-control__error[data-v-7f471fde]{font-weight:300;font-family:\"Roboto\",sans-serif}.form-control__error[data-v-7f471fde]{padding-top:5px;color:#ff8787;font-size:13px}@media(max-width:767px){.form-control__error[data-v-7f471fde]{font-size:12px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
