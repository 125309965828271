<template>
  <div id="header-panel" class="header-panel">
    <!-- временно скрытый прогресс-бар для статей -->

    <!-- <div v-if="isProgressBarShown" ref="progress" class="progress-bar"></div> -->
    <div class="container">
      <div class="header-panel__inner">
        <div class="header-panel__logo">
          <a href="/" class="header-panel__logo-link">
            <img
              src="~/assets/img/main/logo--mts.svg"
              alt="Webinar"
              loading="lazy"
              class="logo__img"
              width="103"
              height="40"
            />
          </a>
        </div>
        <HeaderMenuListDesktop
          :class="['header-panel__list']"
          @openMenu="$emit('openMenu')"
          @closeMenu="$emit('closeMenu')"
        />
        <template v-if="anchorLink !== ''">
          <VButtonMTS
            v-scroll-to="{
              el: anchorLink,
              offset: 0,
              duration: 0,
            }"
            class="header-panel__btn --m --violet-gradient"
            >{{ btnText }}</VButtonMTS
          >
        </template>
        <template v-else>
          <VButtonLinkMTS
            href="/user"
            class="header-panel__btn --m --violet-gradient"
            >Попробовать бесплатно
          </VButtonLinkMTS>
        </template>

        <VButtonLinkMTS
          href="javascript:void(0);"
          ref="siginBtn"
          class="header-panel__btn sigin --m --grey"
          @click="sendMetricsSigninClient"
          >Войти</VButtonLinkMTS
        >

        <button
          class="header-panel__burger-btn"
          data-toggle="slideout-button"
          aria-label="Aria Open menu"
          @click="$emit('openSidebar')"
        >
          <VIconMTS
            :name="'mts/icon-mts--menu'"
            width="24"
            height="24"
            fill="#969FA8"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderPanelMixin from '~/components/Desktop/Header/mixins/HeaderPanelMixin'
import HeaderMenuListDesktop from '~/components/Desktop/Header/HeaderMenuListDesktop.vue'
import VIconMTS from '~/components/Common/MTS/VIconMTS.vue'
import VButtonLinkMTS from '~/components/Common/MTS/VButtonLinkMTS.vue'
import VButtonMTS from '~/components/Common/MTS/VButtonMTS.vue'

export default {
  name: 'HeaderPanelDesktop',
  components: { VIconMTS, VButtonLinkMTS, VButtonMTS, HeaderMenuListDesktop },
  mixins: [HeaderPanelMixin],
  data() {
    return {
      forms: [],
      anchorLink: '',
      currentUrl: '',
      btnText: 'Попробовать бесплатно',
    }
  },
  mounted() {
    // Получаем текущий URL
    this.currentUrl = this.$route.path

    // Проверяем, содержится ли "products" или "task" в URL
    if (
      this.currentUrl.includes('products') ||
      this.currentUrl.includes('task')
    ) {
      this.checkUrlAndSetLinkFormBtn()
    }
  },
  methods: {
    // Для лендингов относяящимся к категории products или task для кнопки будет работать ссылка на форму внизу лендинга,
    checkUrlAndSetLinkFormBtn() {
      // Выполняем поиск всех форм на странице
      this.forms = Array.from(document.getElementsByTagName('form'))
      // Фильтруем формы по классам
      const filteredForms = this.forms.filter((form) => {
        const classes = form.className.split(' ')
        return (
          classes.includes('mts-registration-form__form') ||
          classes.includes('mts-application-form__form')
        )
      })

      // Если есть отфильтрованные формы, выбираем последнюю и устанавливаем id как якорь
      if (filteredForms.length > 0) {
        // Сортируем отфильтрованные формы по порядку их появления (в конце списка)
        const lastForm = filteredForms[filteredForms.length - 1]

        //Поиск ближайшего родителя с id
        const parentLastForm = lastForm.closest('div[id]')
        //для сылки используется id именно родителя. что бы скрол опускался в правильное место
        this.anchorLink = `#${parentLastForm.id}`

        //Заменатекста формы в зависимости от того какая форма используется на стр.
        if (lastForm.className.includes('application')) {
          this.btnText = 'Оставить заявку' // Меняем текст кнопки, если есть "application"
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  transform: translateY(-50vh);
  transition: all 0.3s ease;
  background: #fff;
  border-bottom: 1px solid rgba(150, 159, 168, 0.2);
  font-family: 'MTS Compact', Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  z-index: 449;

  @include screen-down('lg') {
    transform: translateY(-100%);
    padding: 16px 0;
  }

  &.visible {
    transform: translateY(0);
  }

  .container {
    max-width: 1284px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__inner {
    position: relative;
    z-index: 30;
    display: flex;
    align-items: center;
  }

  &__logo {
    &-link {
      display: flex;
      text-decoration: none;
      padding: 0;
      margin-right: 44px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__btn {
    // &.application {
    margin: auto 16px auto auto;
    max-width: 229px;
    // }

    &.sigin {
      flex: 1 85px;
      justify-self: flex-end;
      max-width: 85px;
    }
  }

  &__list {
    flex: 1;
    justify-self: flex-start;
  }

  &__burger-btn {
    width: 44px;
    height: 44px;
    background: none;
    border: none;
    outline: none;
    padding: 10px;
    margin: auto 0 auto 16px;

    @include screen-up('lg') {
      display: none;
    }

    &-icon {
      display: block;
    }
  }
}

// .menu {
//   margin: 0 0 0 auto;
// }

// .progress-bar {
//   position: relative;
//   top: -14px;
//   height: 5px;
//   background: #262626;
// }
</style>
