<template>
  <header class="header-demo _compact" :class="{ '--webinar-group': logoWebinarGroup }">
    <div class="header-demo-bottom">
      <div class="header-demo-inner fw">
        <div class="logo">
          <a href="/" class="logo-link">
            <template v-if="logoWebinarGroup">
              <img class="logo__img" src="~/assets/img/main/logo--mts.svg" alt="logo" width="104" height="40" />
            </template>
            <template v-else>
              <img class="logo__img" src="~/assets/img/main/logo--mts-icon-full-dark.svg" alt="logo" width="104" height="40" />
            </template>
          </a>
        </div>

        <a v-if="!hideBackLink" href="/" class="header-demo-back-link">На главную</a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'HeaderDemoDesktop',
  computed: {
    hideBackLink() {
      const route = this.$route.name
      return route.includes('/tariffs') || route.includes('/billing-registration-custom') || route === 'bill-item'
        || this.$route.path === '/bill/' || this.$route.path === '/partners/'
    },
    logoWebinarGroup() {
      return this.$route.path === '/partners/' ? true : null
    }
  },
};
</script>

<style lang="scss" scoped>
.header-demo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 100;
  min-width: 336px;


  &._compact {
    padding-top: 15px;
  }

  &._compact.--webinar-group {
    max-width: 1280px;
    margin: 0 auto;
    padding: 24px 48px;
    &-bottom {
      padding-top: 0px;
    }

    .header-demo-inner {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-bottom {
    padding-top: 15px;
  }

  &-inner {
    padding-left: 25px;
    padding-right: 25px;
  }

  &-back-link {
    position: absolute;
    font-size: 18px;
    color: #fff;
    right: 45px;
    top: 48px;
    text-decoration: none;
    font-weight: 300;

    &:before {
      content: "";
      position: absolute;
      right: -17px;
      top: 3px;
      width: 11px;
      height: 11px;
      background: url('~assets/img/personaldemo/arrow-back.png') no-repeat 0 0;
    }

    &:hover {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
  }
}

.logo {
  -webkit-transition: .2s;
  transition: .2s;

  &-link {
    display: block;
  }
}
</style>
