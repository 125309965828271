// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/personaldemo/arrow-back.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-demo[data-v-0565479d]{position:absolute;top:0;left:0;right:0;color:#fff;z-index:100;min-width:336px}.header-demo._compact[data-v-0565479d]{padding-top:15px}.header-demo._compact.--webinar-group[data-v-0565479d]{max-width:1280px;margin:0 auto;padding:24px 48px}.header-demo._compact.--webinar-group-bottom[data-v-0565479d]{padding-top:0}.header-demo._compact.--webinar-group .header-demo-inner[data-v-0565479d]{padding-left:0;padding-right:0}.header-demo-bottom[data-v-0565479d]{padding-top:15px}.header-demo-inner[data-v-0565479d]{padding-left:25px;padding-right:25px}.header-demo-back-link[data-v-0565479d]{position:absolute;font-size:18px;color:#fff;right:45px;top:48px;text-decoration:none;font-weight:300}.header-demo-back-link[data-v-0565479d]:before{content:\"\";position:absolute;right:-17px;top:3px;width:11px;height:11px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0}.header-demo-back-link[data-v-0565479d]:hover{border-bottom:1px solid hsla(0,0%,100%,.3)}.logo[data-v-0565479d]{transition:.2s}.logo-link[data-v-0565479d]{display:block}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
