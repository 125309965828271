<template>
  <div class="menu">
    <div class="menu__item-wrapper" @mouseenter="$emit('openMenu')" @mouseleave="$emit('closeMenu')">
      <VDropdownList :toggle-text="'Сервисы'"
        :list-link="toggleNameDropdown == 1 ? productsListLink : industriesListLink" class="menu__item-dropdown --xl"
        :position="translateForContent">
        <template #toggle>
          <div class="menu__item dropdown__btn" @mouseenter="openDropdown(1)">Сервисы</div>
          <div class="menu__item dropdown__btn" @mouseenter="openDropdown(2)">Отрасли</div>
        </template>
        <template #content="{ linkContent }">
          <a :href="linkContent.href" class="menu__item-dropdown_link">
            <VIconMTS :name="'sidebar/' + linkContent.icon.name" width="24" height="24"
              class="menu__item-dropdown_link-icon" fill="#8D969F" />
            {{ linkContent.title }}
          </a>
        </template>
      </VDropdownList>
    </div>
    <a href="/tariffs/" class="menu__item menu__link">Тарифы</a>
    <a href="https://mts-link.ru/enterprise/" class="menu__item menu__link">Крупному бизнесу</a>
  </div>
</template>

<script>
import HeaderMenuListMixin from '~/components/Desktop/Header/mixins/HeaderMenuListMixin';
import VDropdownList from '~/components/Common/VDropdownList.vue';
import VIconMTS from '~/components/Common/MTS/VIconMTS.vue';

export default {
  name: 'HeaderMenuListDesktop',
  components: { VDropdownList, VIconMTS },
  mixins: [HeaderMenuListMixin]
}
</script>

<style lang="scss" scoped>
.menu {
  display: flex;
  align-items: stretch;
  min-height: 52px;

  @include screen-down('lg') {
    display: none;
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 24px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 3px;
      left: 0px;
      bottom: 0;
      border-radius: 50px;
      background: #FF0032;
      transition: all 0.25s ease;
    }

    @include hover-focus-active {
      &:before {
        width: 100%;
      }
    }

    &-wrapper {
      display: flex;
    }
  }

  &__item-dropdown {
    display: flex;

    &_link {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: inherit;
      font-size: 17px;
      line-height: 24px;
      font-weight: 400;
      transition: all 0.3s ease;

      @include hover-focus-active {
        color: #FF0032;
      }

      &-icon {
        display: block;
        margin-right: 12px;
      }
    }

  }

  &__link {
    color: inherit;
    text-decoration: none;
  }

  ::v-deep .dropdown__content_list {
    &-item:not(:last-child) {
      margin-bottom: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      top: -8px;
      width: 20px;
      height: 8px;
      left: 91px;
      background: #fff no-repeat center;
      mask-image: url('~/assets/img/icons/mts/icon-mts--dropdown-arrow.svg')
    }
  }
}
</style>
