<template>
  <div class="form-control" :class="[modifier, { 'is-error': mutatableError }]">
    <client-only>
    <vue-tel-input v-bind="phoneProps" @input="focus" @blur="blur" @validate="updateValue">
    </vue-tel-input>
    </client-only>
    <div v-if="mutatableError" :class="'form-control__error ' + modifier">{{ mutatableError }}</div>
  </div>
</template>

<script>
// передаем modifier:dark-font - темный шрифт для светлых форм
// по умолчанию цвет шрифта белый

const VueTelInput = () =>
  Promise.all([
    import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
    import(/* webpackChunkName: "chunk-vue-tel-input" */ '~/assets/scss/vue-tel-input.scss'),
  ]).then(([{ VueTelInput }]) => VueTelInput);


export default {
  name: 'VPhoneInput',
  components: {
    VueTelInput,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    error: {
      type: String,
      default: null,
    },
    modifier: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      showError: true,
      settedPlaceholder: '',
      mutatableError: null,
      validNumber: '',
      phone: '',
      phoneProps: {
        mode: "international",
        defaultCountry: "RU",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        validCharactersOnly: true,
        placeholder: "Номер телефона",
        enabledCountryCode: false,
        enabledFlags: true,
        preferredCountries: ['RU', 'BY', 'KZ', 'UZ', 'UA'],
        onlyCountries: [],
        ignoredCountries: ['AI', 'AS', 'AG', 'BM', 'VG', 'KY', 'GD', 'GU', 'JM', 'BB', 'VI', 'TC', 'TT', 'VC', 'LC', 'KN', 'MP', 'MS'],
        autocomplete: "mobile tel",
        autoFocus: true,
        name: this.name,
        id: this.id,
        type: this.type,
        maxLen: 25,
        ref: this.id,
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: true
        }
      }
    };
  },
  computed: {
    focusModifier() {
      return this.$attrs.value.length ? 'hide-label' : '';
    },
  },
  watch: {
    error() {
      this.setError();
    },
    value() {
      this.updateValue();
    },
  },
  created() {
    this.$parent.$on('validate', this.setError);
  },
  destroyed() {
    this.$parent.$off('validate', this.setError);
  },
  methods: {
    setError() {
      this.mutatableError = this.error;
    },

    setValue(valueNumber) {
      this.$emit('input', valueNumber);
    },

    updateValue(telnumber) {
      if (telnumber) {
        if (telnumber.valid) {
          this.validNumber = telnumber.number.international
        } else {
          this.validNumber = ''
        }
        this.setValue(this.validNumber);
      }
    },

    focus() {
      this.mutatableError = null;
    },

    blur() {
      this.$emit('blur');
    },
  },
};
</script>

<style lang="scss" scoped>
.vue-tel-input {
  border: none;
  font-family: 'AktivGroteskCorp', sans-serif;

  &:focus-within {
    box-shadow: none;
  }
}

.vti__input {
  background: transparent !important;
  color: #FFFFFF !important;

  &:focus-within {
    box-shadow: none;
    // border: none;
    border-bottom: 1px solid #fff;
  }
}

.vti__dropdown {
  &:hover {
    background: black;
  }

  &.open {
    background: black;
  }
}

.vti__dropdown-list {
  background: black;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.vti__dropdown-item.highlighted {
  background: rgba(255, 255, 255, 0.2);
}

.vti__dropdown-item.last-preferred {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.form-control {
  display: flex;
  flex-direction: column;
  transition: all cubic-bezier(0.25, 0.8, 0.25, 1) 0.25s;
  min-height: 66px;

  .vue-tel-input {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 6px;
  }

  &__error {
    order: 1;
    color: #ff8787;
    padding-top: 5px;
    font-size: 13px;
    font-weight: 300;
    text-align: left;
    line-height: 1.3;
  }
}
</style>

