<template>
  <FooterDesktop />
</template>
<script>
const FooterDesktop = () => import(
    /* webpackChunkName: "FooterDesktop" */
    '~/components/Desktop/Footer/FooterDesktop.vue'
  );
export default {
  name: 'LazyFooterDesktop',
  components: {
    FooterDesktop,
  }
}
</script>
