<template>
  <div class="footer-menu">
    <div v-for="(column, index) in menu" :key="index" class="footer-menu__column">
      <div v-for="(item, index) in column" :key="index" class="footer-menu__column-item">
          <a
            :href="item.href"
            :rel="item.rel"
            :target="item.target"
            class="footer-menu__column-item-link"
          >
            {{ item.title }}
          </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterMenuMobile',
  props: {
    menu: {
      type: Array,
      default: () => ([]),
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-menu {

  &__column {
    display: grid;
    row-gap: 16px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    &-item {

      &-link {
        color: $color-gray-1;
        text-decoration: none;
        font-size: 17px;
        line-height: 24px;
      }
    }
  }
}
</style>
