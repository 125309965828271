<template>
  <header class="header">
    <HeaderBannerDesktop v-if="headerBannerForProductsMessenger" />
    <div class="container header__container">
      <div v-if="showContacts" class="header-top">
        <VDropdownList
          class="header-top__dropdown --s"
          :list-link="phoneListLinkForDropdown"
        >
          <template #toggle>
            <a class="header-top__link dropdown__btn" href="tel: +78003505490">
              <VIconMTS
                :name="'mts/icon-mts--phone-header'"
                width="16"
                height="20"
                fill="#969FA8"
                class="header-top__link-icon"
              />

              +7 (800) 350-54-90 <span class="header-top__place">Россия</span>

              <VIconMTS
                :name="'mts/icon-mts--arrow-down'"
                width="16"
                height="16"
                fill="#1D2023"
                class="header-top__link-icon"
              />
            </a>
          </template>
          <template #content="{ linkContent }">
            <a :href="'tel:+' + linkContent.href" class="header-top__link">
              <VIconMTS
                :name="'mts/icon-mts--phone-header'"
                width="16"
                height="20"
                fill="#969FA8"
                class="header-top__link-icon"
              />

              {{ linkContent.phone }}
              <span class="header-top__link-place"
                >&nbsp;{{ linkContent.place }}</span
              >
            </a>
          </template>
        </VDropdownList>

        <a href="/faq_and_support/" class="header-top__link">
          <VIconMTS
            :name="'mts/icon-mts--support'"
            width="16"
            height="20"
            fill="#969FA8"
            class="header-top__link-icon"
          />
          <span>Поддержка 24/7</span>
        </a>
      </div>
      <div class="header-bottom">
        <a href="/" class="header-bottom__logo logo">
          <img
            src="~/assets/img/main/logo--mts.svg"
            alt="Webinar"
            class="logo__img"
            width="104"
            height="40"
          />
        </a>
        <HeaderMenuListDesktop
          :class="['header-bottom__list']"
          @openMenu="$emit('openMenu')"
          @closeMenu="$emit('closeMenu')"
        />

        <VButtonLinkMTS
          href="javascript:void(0);"
          class="header-bottom__btn sigin --m --grey"
          @click="sendMetricsSigninClient"
        >
          Войти
        </VButtonLinkMTS>

        <button
          class="header-bottom__burger-btn"
          data-toggle="slideout-button"
          aria-label="Aria Open menu"
          @click="$emit('openSidebar')"
        >
          <VIconMTS
            :name="'mts/icon-mts--menu'"
            class="header-bottom__burger-icon"
            width="24"
            height="24"
            fill="#969FA8"
          />
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import HeaderMixin from '~/components/Desktop/Header/mixins/HeaderMixin'
import VDropdownList from '~/components/Common/VDropdownList.vue'
import VButtonLinkMTS from '~/components/Common/MTS/VButtonLinkMTS.vue'
import HeaderMenuListDesktop from '@/components/Desktop/Header/HeaderMenuListDesktop.vue'
import VIconMTS from '~/components/Common//MTS/VIconMTS.vue'
import HeaderBannerDesktop from '~/components/Desktop/Header/HeaderBannerDesktop.vue'

export default {
  name: 'HeaderDesktop',
  components: {
    HeaderMenuListDesktop,
    VDropdownList,
    VIconMTS,
    VButtonLinkMTS,
    HeaderBannerDesktop,
  },
  mixins: [HeaderMixin],
}
</script>

<style lang="scss" scoped>
.header {
  font-family: 'MTS Compact', 'Arial', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  position: relative;
  z-index: 450;
  background: #fff;
  padding-bottom: 14px;
  border-bottom: 1px solid rgba(150, 159, 168, 0.2);
  color: $color-dark-2;

  &__container {
    max-width: 1284px;
    padding-left: 10px;
    padding-right: 10px;
  }

  &-top {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 32px;

    &__dropdown {
      ::v-deep(.dropdown__content_list) {
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
      }
    }

    &__link {
      display: block;
      color: #969fa8;
      text-decoration: none;
      transition: all 0.3s ease;

      &:hover {
        color: #ff0032;
      }

      &-icon {
        margin-right: 4px;
        vertical-align: text-bottom;
      }

      &-place {
        color: #1d2023;
      }
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &__btn {
      &.application {
        margin: auto 16px auto auto;
        max-width: 169px;
      }

      &.sigin {
        flex: 1 85px;
        max-width: 85px;
      }
    }

    &__burger {
      &-btn {
        width: 44px;
        height: 44px;
        background: none;
        border: none;
        outline: none;
        padding: 10px;
        margin: auto 0 auto 16px;

        @include screen-up('lg') {
          display: none;
        }
      }

      &-icon {
        display: block;
      }
    }

    &__list {
      flex: 1;
      justify-self: flex-start;
    }
  }

  .logo {
    display: block;
    margin-right: 44px;
    @include screen-down('lg') {
      flex: 1;
    }
  }
}
</style>
