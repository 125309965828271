<template>
    <div
      class="form-success-message"
      :class="[modifier, {'success-error': !success}]"
      >
      <div class="form-success-message__title">{{title}}</div>
      <div class="form-success-message__text" v-html="message"></div>
      <span class="form-success-message__back-link"
            v-if="modifier === 'billing' && canRetry"
            @click="returnToForm">Вернуться к форме</span>
    </div>
</template>

<script>

export default {
  name: 'FormMessage',
  props: {
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    success: {
      type: Boolean,
    },
    modifier: {
      type: String,
    },
    canRetry: {
      type: Boolean,
    },
  },
  methods: {
    returnToForm() {
      this.$emit('returnToForm')
    }
  }
};
</script>

<style lang="scss" scoped>

  .form-success-message {
    font-family: "AktivGroteskCorp", "Arial", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: .4s;
    padding-top: 120px;
    position: relative;
    color: #fff;
    font-weight: 300;
  }

  .form-success-message__title {
    font-size: 25px;
    margin-bottom: 20px;
  }

  .form-success-message__text {
    font-size: 18px;
    opacity: 0.6;
    line-height: 1.7;
  }

  .billing {
    .form-success-message:not(.success-error)::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -52px;
      width: 103px;
      height: 85px;
      background: url("~assets/img/icons/sucsses-icon.png") no-repeat 0 0;
    }

    .form-success-message__title {
      color: #ffb300;
    }

    .form-success-message__back-link {
      color: #fff;
      font-size: 18px;
      position: relative;
      text-decoration: none;
      cursor: pointer;
      margin-top: 25px;

      &::before {
        transform: rotate(180deg);
        content: "";
        position: absolute;
        left: -17px;
        top: 5px;
        width: 11px;
        height: 11px;
        background: url("~assets/img/icons/arrow-back.png") no-repeat 0 0;
      }
    }
  }

  .popup {

    .form-success-message__title::before {
      content: "";
      position: absolute;
      top: -35px;
      left: 50%;
      transform: translateX(-50%);
      width: 228px;
      height: 228px;
      background: url("~assets/img/icons/popup-sent.png") no-repeat 0 0;
    }

    .form-success-message__title {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: #3d3d3d;
      margin: 0 0 5px;
      padding-top: 80px;
    }

    .form-success-message__text {
      max-width: none;
      margin: 0 auto 20px;
      margin-bottom: 8px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #707070;
    }
  }

  .request {
    color: #333333;
  }

</style>
