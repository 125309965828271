<template>
  <footer class="footer">
    <div class="inner-width">
      <div class="footer-top fw">
        <div class="bottom-breadcrumbs">
          <ul class="bottom-breadcrumbs-list">
            <li class="bottom-breadcrumbs-list-item">
              <a href="/" class="bottom-breadcrumbs-list-item__home-link icon-home"></a>
            </li>

            <li v-if="!mobileWidth" class="bottom-breadcrumbs-list-item">
              <span class="bottom-breadcrumbs-list-item__cur">{{ bottomBreadcrumbs }}</span>
            </li>

            <li v-else v-click-outside="closeMenu" class="flexMenu-viewMore">
              <a href="javascript:void(0)" title="Еще" @click="toggleMenu">...</a>

              <ul v-if="showMenu" class="flexMenu-popup" style="position: absolute">
                <li class="bottom-breadcrumbs-list-item">
                  <span class="bottom-breadcrumbs-list-item__cur">{{ bottomBreadcrumbs }}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <div class="footer-bottom fw">
        <div class="copyright">
          <span class="footer-bottom__copyright-text">©&nbsp;Webinar Group, 2008-{{ currentYear }}</span>
          <span class="footer-bottom__copyright-text">"МТС Линк", "Webinar", "We.Study", "COMDI" — товарные знаки, используемые Webinar Group</span>
        </div>

        <div class="footer-soclinks">
          <div class="soclinks">
            <a href="" class="soclink-item _vk icon-vkontakte" rel="nofollow"></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import FooterEmptyMixin from "~/components/Desktop/Footer/mixins/FooterEmptyMixin";

export default {
  name: 'FooterEmptyDesktop',
  mixins: [FooterEmptyMixin],
};
</script>

<style lang="scss" scoped>
.footer {
  color: #fff;
  background: #282a2e;
  background: -webkit-linear-gradient(top, #282a2e 0%, #222427 100%);
  background: linear-gradient(to bottom, #282a2e 0%, #222427 100%);

  &-top {
    padding: 80px 0 0;
    display: block;
    border-bottom: none;
  }

  &-bottom {
    padding: 0 0 25px;
  }

  &-soclinks {
    float: right;
  }
}

.soclink-item:before {
  font-size: 17px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -8px;
  margin-top: -8px;
}

.bottom-breadcrumbs {
  margin-bottom: 45px;
  position: relative;
  padding: 0 20px;
}

.bottom-breadcrumbs:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
}

.bottom-breadcrumbs-list {
  list-style: none;
  letter-spacing: -.36em;
  font-size: 0;
  text-align: center;
  height: 40px;
}

.bottom-breadcrumbs-list > li {
  display: inline-block;
  vertical-align: top;
  height: 40px;
  font-size: 13px;
  letter-spacing: normal;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-left: none;
  background: #27292d;
  position: relative;
  padding: 14px 15px 0 15px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.2);
}

.bottom-breadcrumbs-list > li:first-child {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.bottom-breadcrumbs-list > li:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.bottom-breadcrumbs-list > li:last-child:before,
.bottom-breadcrumbs-list > li:last-child:after {
  display: none;
}

.bottom-breadcrumbs-list > li:before,
.bottom-breadcrumbs-list > li:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -5px;
  border: 5px solid transparent;
  border-right-width: 0;
  z-index: 1;
}

.bottom-breadcrumbs-list > li:before {
  border-left-color: rgba(255, 255, 255, 0.1);
  right: -5px;
}

.bottom-breadcrumbs-list > li:after {
  border-left-color: #27292d;
  right: -4px;
}

.bottom-breadcrumbs-list > li > .bottom-breadcrumbs-list-item__link,
.bottom-breadcrumbs-list > li > .bottom-breadcrumbs-list-item__home-link {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.2);
  -webkit-transition: .2s;
  transition: .2s;
}

.bottom-breadcrumbs-list > li > .bottom-breadcrumbs-list-item__link:hover,
.bottom-breadcrumbs-list > li > .bottom-breadcrumbs-list-item__home-link:hover {
  color: #fff;
}

.bottom-breadcrumbs-list > li > .bottom-breadcrumbs-list-item__home-link {
  position: relative;
  top: -4px;
  left: 2px;
}

.bottom-breadcrumbs-list > li > .bottom-breadcrumbs-list-item__home-link:before {
  padding: 0;
  margin: 0;
  font-size: 18px;
}

.bottom-breadcrumbs-list-item__home-link:hover {
  color: #fff;
}

.bottom-breadcrumbs-list .flexMenu-viewMore > a {
  display: inline-block;
  padding-bottom: 5px;
  top: -9px;
  position: relative;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.2);
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
  -webkit-transition: .2s;
  transition: .2s;
}

.bottom-breadcrumbs-list .flexMenu-viewMore > a:hover {
  color: #fff;
}

.bottom-breadcrumbs-list .flexMenu-popup {
  width: 250px;
  background: #27292d;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  left: 50%;
  margin-left: -125px;
  top: 100%;
  margin-top: 20px;
  list-style: none;
  padding: 8px 0;
  z-index: 5;
  box-shadow: 0 0 38px rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    width: 220px;
    margin-left: -110px;
  }
}

.bottom-breadcrumbs-list .flexMenu-popup:before {
  content: "";
  position: absolute;
  left: 50%;
  top: -10px;
  margin-left: -10px;
  border: 10px solid transparent;
  border-top-width: 0;
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.bottom-breadcrumbs-list .flexMenu-popup:after {
  content: "";
  position: absolute;
  left: 50%;
  top: -9px;
  margin-left: -10px;
  border: 10px solid transparent;
  border-top-width: 0;
  border-bottom-color: #27292d;
}

.bottom-breadcrumbs-list .flexMenu-popup .bottom-breadcrumbs-list-item__link {
  display: block;
  padding: 10px 17px;
  font-size: 13px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.2);
  text-align: left;
  line-height: 1.3;
  -webkit-transition: .2s;
  transition: .2s;
}

.bottom-breadcrumbs-list .flexMenu-popup .bottom-breadcrumbs-list-item__link:hover,
.bottom-breadcrumbs-list .flexMenu-popup .bottom-breadcrumbs-list-item__link.is-active {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.bottom-breadcrumbs-list .flexMenu-popup .bottom-breadcrumbs-list-item__cur {
  display: block;
  padding: 10px 17px;
  font-size: 13px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.2);
  text-align: left;
  line-height: 1.3;
  -webkit-transition: .2s;
  transition: .2s;
}
</style>
