export default {
  data() {
    return {
      title: 'МТС Линк',
      description: `
        Сервисы для бизнес-коммуникаций и\u00A0совместной работы. Совещания, вебинары,
        корпоративный мессенджер, онлайн-доски и\u00A0обучающие курсы в\u00A0одной экосистеме
      `,
      menu: [
        [
          {
            title: 'О нас',
            href: '/about_service/',
            target: '',
            rel: '',
          },
          {
            title: 'Тарифы',
            href: '/tariffs/',
            target: '',
            rel: '',
          },
          {
            title: 'Контакты',
            href: '/contacts/',
            target: '',
            rel: '',
          },
          {
            title: 'Пресс-центр',
            href: '/news/',
            target: '',
            rel: '',
          },
          {
            title: 'Вакансии',
            href: 'https://job.mts-link.ru/',
            target: '_blank',
            rel: 'noreferrer nofollow noopener',
          },
          {
            title: 'Партнерство',
            href: '/partners/',
            target: '',
            rel: '',
          },
        ],
        [
          {
            title: 'База знаний',
            href: 'https://help.mts-link.ru/',
            target: '_blank',
            rel: 'noreferrer nofollow noopener',
          },
          {
            title: 'МТС Линк Медиа',
            href: '/blog/',
            target: '',
            rel: '',
          },
          {
            title: 'Обучающие материалы',
            href: 'https://learn.mts-link.ru/',
            target: '_blank',
            rel: 'noreferrer nofollow noopener',
          },
          {
            title: 'Карта сайта',
            href: '/sitemap/',
            target: '',
            rel: '',
          },
          {
            title: 'Поддержка 24/7',
            href: '/faq_and_support/',
            target: '',
            rel: '',
          },
          {
            title: 'Bug Bounty',
            href: 'https://bugbounty.mts-link.ru/',
            target: '_blank',
            rel: 'noreferrer nofollow noopener',
          },
        ],
      ],
      links: [
        {
          title: 'Политика в отношении обработки персональных данных',
          href: '/privacy-policy/',
          target: '',
          rel: '',
        },
        {
          title: 'Деловая этика и комплаенс',
          href: '/komplaens-i-delovaya-etika/',
          target: '',
          rel: '',
        },
        {
          title: 'Правовая информация',
          href: '/policy/',
          target: '',
          rel: '',
        },
      ],
      socialList: [
        {
          href: 'https://t.me/mts_link',
          icon: 'social/icon-mts--telegram',
        },
        {
          href: 'https://vk.com/mtslink',
          icon: 'social/icon-mts--vk',
        },
        {
          href: 'https://rutube.ru/channel/42339046/',
          icon: 'social/icon-mts--rutube',
        },
        {
          href: 'https://www.youtube.com/@mtslink',
          icon: 'social/icon-mts--youtube',
        },
      ],
      bottom: `
        <div>© Webinar Group, 2008–2024. «МТС Линк», «Webinar», «We.Study», «Comdi» —</div>
        <div>товарные знаки, используемые Webinar Group</div>
      `,
      windowWidth: null,
    }
  },
  computed: {
    showLine () {
      return !this.$route.path.includes('/application');
    },
    responsiveIcon() {
      if (this.windowWidth > 991) {
        return { color: '#BBC1C7', width: '44', height: '44' }
      } else {
        return { color: '#BBC1C7', width: '32', height: '32' }
      }
    },
  },
  methods: {
    updateWindowSize() {
      this.windowWidth = window.innerWidth
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateWindowSize)
    window.addEventListener('load', this.updateWindowSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowSize)
  },
}
