<template>
  <div class="form-control" :class="[modifier.join(' '), { 'is-error': isError }, focusModifier]">
    <div class="form-control__select">
      <label class="form-control__select-label">
        <div v-if="descr" class="form-control__descr">
          {{ descr }}
        </div>
        {{ label }}
      </label>
      <select v-model="selectedOption" :class="['form-control__select-body', { 'is-error': isError }]" :name="name"
        @focus="focus" @blur="blur" @change="updateValue">
        <option v-for="(option, $index) in options" :key="$index" :value="option.value" :disabled="option.disabled">
          {{ ($index === 0 && option.label === '') ? firstEmptyVariantLabel : option.label }}
        </option>
      </select>
    </div>

    <div v-if="mutatableError" class="form-control__error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'VDropdown',
  props: {
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    modifier: {
      type: Array,
      default: () => [],
    },
    descr: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
    modifierSelect: {
      type: String,
      default: 'form-control',
    },
    firstEmptyVariantLabel: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      selectedOption: null,
      lastSelectedOption: '',
      mutatableError: null,
      isError: false,
    };
  },
  computed: {
    focusModifier() {
      return this.selectedOption !== '' ? 'hide-label' : '';
    },
  },
  watch: {
    error() {
      this.mutatableError = this.error;
      this.isError = true;
    },
  },
  mounted() {
    this.selectedOption = this.options[0].value;
  },
  updated() {
    this.selectedOption = this.value ?? this.options[0].value;
  },
  methods: {
    updateValue() {
      if (this.selectedOption !== this.lastSelectedOption) {
        this.lastSelectedOption = this.selectedOption;
        this.$emit('input', this.selectedOption);
        this.mutatableError = null;
        this.isError = false;
      }
    },
    focus() {
      this.mutatableError = null;
      this.isError = false;
    },

    blur() {
      this.$emit('blur');
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  display: flex;
  flex-direction: column;
  min-height: 66px;
  &.sidebar-mode {
    &.hide-label {
      .form-control__select-label {
        transform: translateY(0px);
        font-size: 12px;
        line-height: 12px;
        color: #919191;
      }
    }
  }

  &__select {
    display: inline-block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 75%;
      right: 0;
      transform: translateY(-75%);
      border: 4px solid transparent;
      border-top: 4px solid #fff;
      opacity: 0.8;
    }

    &-label {
      display: inline-block;
      font-family: Roboto, sans-serif;
      font-weight: 300;
      font-size: 14px;
      color: #fff;
      transform: translateY(17px);
      -webkit-transition: all cubic-bezier(0.25, 0.8, 0.25, 1) 0.25s;
      transition: all cubic-bezier(0.25, 0.8, 0.25, 1) 0.25s;
      opacity: 0.8;
    }

    &-body {
      font-size: 12px;
      display: block;
      position: relative;
      padding-bottom: 6px;
      border: none;
      outline: none;
      font-family: "AktivGroteskCorp", "Arial", sans-serif;
      appearance: none;
      z-index: 10;
      cursor: pointer;

      .sidebar-mode & {
        font-weight: 400;
        font-size: 14px;
        width: 100%;
        background: url("@/assets/img/arrow-select.svg") no-repeat right 22px transparent;
        color: rgba(255, 255, 255, 0.87);

        option {
          background: #000000;
          cursor: pointer;

          &:checked {
            background-color: #673AB7;
          }
        }
      }
    }
  }

  &__descr {
    font-weight: 300;
    font-size: 12px;
    color: #FFFFFF;
    opacity: 0.4;
    text-align: left;
    line-height: 16px;
    font-family: "Roboto", sans-serif;
  }

  &__error {
    padding-top: 5px;
    color: #ff8787;
    font-size: 13px;
    font-weight: 300;
    font-family: "Roboto", sans-serif;

    @include screen-down("md") {
      font-size: 12px;
    }
  }
}

</style>
