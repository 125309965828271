<template>
  <SidebarDesktop @closeSidebar="closeSidebar" />
</template>
<script>
const SidebarDesktop = () => import(
  /* webpackChunkName: "SidebarDesktop" */
  '~/components/Desktop/Common/SidebarDesktop.vue'
);
export default {
  name: 'LazySidebarDesktop',
  components: {
    SidebarDesktop,
  },
  methods: {
    closeSidebar() {
      this.$emit('closeSidebar');
    }
  },
}
</script>
