<template>
  <div class="error-page">
    <PromoBgMobile
      :title="'404'"
      :modifier="'error-page'"
    />

    <section class="error-page__section">
      <div class="container">
        <h2 class="error-page__title">Страница не найдена</h2>
        <p class="error-page__description">Похоже, что вы перешли по неправильной или устаревшей ссылке. Но не
          волнуйтесь — все найдется! Попробуйте поискать в одном из следующих разделов.</p>
        <ul class="error-page__dop-links">
          <li class="error-page__item">
            <div class="error-page__item-title">
              <a href="/functions/">Продукт</a>
            </div>
          </li>
          <li class="error-page__item">
            <div class="error-page__item-title">
              <a href="/tariffs/">Тарифы</a>
            </div>
          </li>
          <li class="error-page__item">
            <div class="error-page__item-title">
              <a href="/livedemo/">Демо-вебинары</a>
            </div>
          </li>
          <li class="error-page__item">
            <div class="error-page__item-title"><a href="/clients/">Клиенты и кейсы</a></div>
          </li>
          <li class="error-page__item">
            <div class="error-page__item-title">
              <a href="/about_service/">О МТС Линк</a>
            </div>
          </li>
          <li class="error-page__item">
            <div class="error-page__item-title">
              <a href="/contacts/">Контакты</a>
            </div>
          </li>
        </ul>
      </div>
    </section>

    <TryNewPlatformForm/>
  </div>
</template>
<script>

import PromoBgMobile from "~/components/Mobile/Common/PromoBgMobile.vue";
import TryNewPlatformForm from "~/components/Common/Form/TryNewPlatformForm.vue";

export default {
  name: 'ErrorPageMobile',
  components: {TryNewPlatformForm, PromoBgMobile},
}
</script>
<style lang="scss" scoped>
.promo {
  padding-bottom: 87px;
}

.error-page {
  &__section {
    background: #f5f5f5;
    border-bottom: solid 10px #fff;
    padding: 115px 0;

    .container {
      max-width: 670px;
    }
  }

  &__number {
    margin: 0 auto;
    max-width: 1000px;
    font-size: 420px;
    font-weight: 700;
    color: $color-white;
    opacity: 0.8;
    text-align: center;
  }

  &__title {
    font-size: 30px;
    line-height: 1.24;
    font-weight: 300;
    font-family: "AktivGroteskCorp", "Arial", sans-serif;
    margin: 0 0 28px 0;
  }

  &__description {
    font-family: "AktivGroteskCorp", "Arial", sans-serif;
    font-size: 18px;
    line-height: 1.45;
    margin-bottom: 32px;
    font-weight: 300;
  }

  &__dop-links {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: block;
    font-family: "AktivGroteskCorp", "Arial", sans-serif;
    position: relative;
    font-size: 19px;
    line-height: 1.6;
    font-weight: 300;
    width: 100%;
    margin-bottom: 35px;
    padding-left: 30px;

    @media (min-width: 480px) {
      width: 45%;
      margin: 0 0 51px 9%;
    }

    &:nth-child(2n+1) {
      margin-left: 0;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 18px;
      height: 1px;
      background: #111;
    }

    a {
      text-decoration: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      transition: .2s;
      color: #111;

      &:hover,
      &:focus {
        border-bottom-color: #111;
      }
    }
  }
}
</style>
