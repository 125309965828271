<template>
  <div class="promo" :class="modifier">
    <div class="promo__bg">
      <picture v-if="bgImgSrc || bgImgSrcWebp">
        <template v-if="bgLazy">
          <source v-lazy-load :data-srcset="bgImgSrcWebp">
          <img v-lazy-load :data-src="bgImgSrc" alt="фон">
        </template>

        <template v-else>
          <source :srcset="bgImgSrcWebp">
          <img :src="bgImgSrc" alt="фон">
        </template>
      </picture>

      <picture v-else>
        <template v-if="bgLazy">
          <source v-lazy-load data-srcset="~assets/img/content-page-promo-bg.webp">
          <img v-lazy-load data-src="~assets/img/content-page-promo-bg.jpg" alt="фон">
        </template>

        <template v-else>
          <source srcset="~assets/img/content-page-promo-bg.webp">
          <img src="~assets/img/content-page-promo-bg.jpg" alt="фон">
        </template>
      </picture>
    </div>

    <div class="promo-container">
      <div class="promo-info">
        <slot name="beforeTitle"></slot>
        <h1 v-if="title" class="promo-title">{{ title }}</h1>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromoBgMobile',
  props: {
    title: {
      type: String,
      default: '',
    },
    bgImgSrc: {
      type: String,
    },
    bgImgSrcWebp: {
      type: String,
    },
    modifier: {
      type: String,
    },
    bgLazy: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style lang="scss" scoped>
.promo {
  position: relative;
  height: auto;
  min-height: 490px;
  padding: 230px 0 105px;
  text-align: center;
  color: #fff;
  background-size: auto 100%;
  background: #0e0e0e;
  margin-top: -110px;

  @media (max-width: 560px) {
    padding-bottom: 24px;
  }

  @media (max-width: 479px) {
    padding-top: 180px;
  }




  &--faq {
    padding: 161px 0 20px;
    height: auto;
    min-height: auto;

    .promo-title {
      max-width: 600px;
      font-size: 42px;
      margin-bottom: 16px;

      @media(min-width: 560px) {
        font-size: 60px;
      }
    }

    .promo__bg {
      img {
        object-position: center bottom;
      }

      &:after {
        opacity: 0.2;
      }
    }
  }

  &--faq-system {
    padding: 161px 0 20px;
    height: auto;
    min-height: auto;

    .promo-title {
      max-width: 600px;
      font-size: 24px;
      margin-bottom: 16px;

      @media(min-width: 450px) {
        font-size: 40px;
      }
    }

    .promo__bg {
      img {
        object-position: center bottom;
      }

      &:after {
        opacity: 0.2;
      }
    }
  }

  &--clients {
    height: auto;
    min-height: 100%;
    padding: 100px 0 50px 0;

    .promo-container {
      position: relative;
      display: table;
      width: 100%;
      height: 100%;
    }

    .promo-title {
      font-size: 30px;
      line-height: 1.5;

      @media (max-width: 560px) {
        font-size: 42px;
        line-height: 1.1;
      }
    }

    .promo-info {
      display: table-cell;
      vertical-align: middle;
      padding-top: 75px;
    }

    .promo__bg {
      &:after {
        opacity: 0.6;
      }
    }
  }

  &--about-service {
    max-height: 650px;

    @media (max-width: 1023px) {
      padding: 170px 10px 0;
    }

    .promo {
      &-container {
        padding: 0;
      }

      &__bg::after {
        background: linear-gradient(70.17deg, #20009E 0%, rgba(40, 0, 199, 0) 100%);
        opacity: 0.5;
      }

      &-info {
        padding-top: 0;
      }
    }
  }


  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #000;
      opacity: 0.3;
    }
  }

  &-container {
    width: auto;
    margin: 0 auto;
    padding: 25px 15px 0 15px;

    @media (min-width: 479px) {
      padding: 25px 30px 0 30px;
    }
  }

  &-info {
    position: relative;
    padding-top: 50px;
  }

  &-title {
    font-family: "AktivGroteskCorp", "Arial", sans-serif;
    margin: 0 auto 87px;
    max-width: 1000px;
    font-size: 30px;
    line-height: 1.25;
    font-weight: 300;

    @media (min-width: 560px) {
      font-size: 42px;
      line-height: 1.25;
    }
  }

  &.error-page {
    padding-top: 150px;
    display: flex;
    flex-direction: column;

    @media (max-width: 560px) {
      padding-top: 130px;
      padding-bottom: 30px;
      min-height: 447px;
    }

    .promo-container {
      padding-top: 0;
      flex-grow: 1;

      @media (max-width: 560px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .promo-info {
      padding-top: 0;
    }

    .promo-title {
      font-family: "AktivGroteskCorp", "Arial", sans-serif;
      margin: 0 auto 0 auto;
      max-width: 1000px;
      font-size: 100px;
      line-height: 1;
      font-weight: 300;
      color: $color-white;
      opacity: 0.8;
      text-align: center;

      @media (min-width: 560px) {
        font-size: 200px;
        margin-bottom: 50px;
      }
    }
  }
}
</style>
