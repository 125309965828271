
export function scrollToSection(id, correction = 0) {
  window.scrollTo({
    top: document.querySelector(`#${id}`).offsetTop - correction,
    behavior: 'smooth',
  })
}

export function logToConsole(message, extra = {}) {
  if (typeof localStorage !== 'undefined' && localStorage.debug === 'true') {
    /* eslint-disable no-console */
    console.log(message, extra);
    /* eslint-enable no-console */
  }
}

export async function logToBackend(axios, level, message, extra = {}) {
  try {
    if (typeof window !== 'undefined') {
      extra.url = window.location.href
    }
    const res = await axios.$post(`/log`, {level, message, extra});
    if (!res) {
      logToConsole('Error: can\'t send log to backend.');
    }
  } catch (e) {
    logToConsole('Error: can\'t send log to backend.', {'axios_exception': e.message});
  }
}
