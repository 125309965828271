<template>
  <footer class="footer" :class="{ '--with-border' : showLine }">
    <div class="container">
      <div class="footer__wrapper">
        <div>
          <h4 class="footer__title">{{ title }}</h4>

          <div class="footer__description">{{ description }}</div>
        </div>

        <FooterMenuMobile v-if="menu.length" :menu="menu"/>

        <SocialLinksBlock :icon="{ color: '#BBC1C7', width: '32', height: '32' }" :social-list="socialList"/>

        <a
          class="footer__download"
          href="/application/"
          rel="noreferrer nofollow noopener"
          target="_blank"
        >
          <VIconMTS
            class="footer__download-icon"
            name="mts-brand"
          />

          <div>
            <div class="footer__download-title">МТС Линк</div>

            <div class="footer__download-text">Скачать</div>
          </div>
        </a>

        <div class="footer__links">
          <a
            v-for="(item, $index) in links"
            :key="$index"
            :href="item.href"
            :rel="item.rel"
            :target="item.target"
            class="footer__links-item"
          >
            {{ item.title }}
          </a>
        </div>

        <div class="footer__bottom" v-html="bottom"/>
      </div>
    </div>
  </footer>
</template>

<script>
import VIconMTS from "@/components/Common/MTS/VIconMTS";
import FooterMenuMobile from "@/components/Mobile/Footer/FooterMenuMobile";
import FooterMixin from "@/components/Desktop/Footer/mixins/FooterMixin";
import SocialLinksBlock from "@/components/Common/SocialLinksBlockMTS";

export default {
  name: 'FooterMobile',
  components: {
    SocialLinksBlock,
    FooterMenuMobile,
    VIconMTS,
  },
  mixins: [FooterMixin],
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: $color-gray-2;
  padding: 20px 0;
  font-family: $font-mts-compact;

  & .container {
    padding: 0 20px;
  }

  &.--with-border {
    border-top: 1px solid rgba(188, 195, 208, 0.5);
  }

  &__wrapper {
    display: grid;
    row-gap: 40px;
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 8px;
    color: $color-dark-2;
  }

  &__description {
    color: $color-dark-2;
  }

  &__description,
  &__bottom {
    font-size: 17px;
    line-height: 24px;
  }

  &__download {
    display: grid;
    grid-template-columns: 32px 1fr;
    column-gap: 12px;
    color: inherit;
    text-decoration: none;

    &-icon {
      width: 32px;
      height: 32px;
    }

    &-title,
    &-text {
      font-size: 14px;
      line-height: 20px;
    }

    &-text {
      color: $color-gray-1;
    }

    &-title {
      color: $color-dark-2;
    }
  }

  &__links {
    display: grid;
    row-gap: 16px;

    &-item {
      color: $color-gray-1;
      text-decoration: none;
      font-size: 17px;
      line-height: 24px;
      text-decoration: none;
    }
  }

  &__bottom {
    color: $color-gray-1;
  }
}
</style>
