<template>
  <div class="promo" :class="modifier">
    <div class="promo__bg">
      <picture v-if="bgImgSrc || bgImgSrcWebp">
        <template v-if="bgLazy">
          <source v-lazy-load :data-srcset="bgImgSrcWebp">
          <img v-lazy-load :data-src="bgImgSrc" alt="фон">
        </template>

        <template v-else>
          <source :srcset="bgImgSrcWebp">
          <img :src="bgImgSrc" alt="фон">
        </template>
      </picture>

      <picture v-else>
        <template v-if="bgLazy">
          <source v-lazy-load data-srcset="~assets/img/content-page-promo-bg.webp">
          <img v-lazy-load data-src="~assets/img/content-page-promo-bg.jpg" alt="фон">
        </template>

        <template v-else>
          <source srcset="~assets/img/content-page-promo-bg.webp">
          <img src="~assets/img/content-page-promo-bg.jpg" alt="фон">
        </template>
      </picture>
    </div>

    <div class="promo-container">
      <div class="promo-info">
        <slot name="beforeTitle"></slot>
        <h1 v-if="title" class="promo-title">{{ title }}</h1>
        <slot></slot>
      </div>

      <div v-if="modifier === 'promo--clients'" class="promo-bottom-controls">
        <div class="inner-width">
          <div class="promo-bottom-controls-line">
            <button type="button" class="promo-bottom-controls-arrow-btn" @click.prevent="goTo">
              <span class="promo-bottom-controls-arrow-btn__arrow">
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PromoBgDesktop',
  props: {
    title: {
      type: String,
      default: '',
    },
    bgImgSrc: {
      type: String,
    },
    bgImgSrcWebp: {
      type: String,
    },
    modifier: {
      type: String,
    },
    bgLazy: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    goTo() {
      const destination = document.querySelector('.js-next-section');

      if (destination) {
        window.scroll({
          top: destination.offsetTop - 60,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.promo {
  position: relative;
  height: auto;
  min-height: 490px;
  padding: 230px 0 105px;
  text-align: center;
  color: #fff;
  background-size: auto 100%;
  background: #0e0e0e;
  margin-top: -110px;



  &--faq {
    padding: 161px 0 20px;
    height: auto;
    min-height: auto;

    .promo-title {
      max-width: 600px;
      font-size: 40px;
      margin-bottom: 16px;

      @media(max-width: 960px) {
        font-size: 72px;
      }
    }

    .promo__bg {
      img {
        object-position: center bottom;
      }

      &:after {
        opacity: 0.2;
      }
    }
  }

  &--faq-system {
    padding: 161px 0 20px;
    height: auto;
    min-height: auto;

    .promo-title {
      max-width: 600px;
      font-size: 40px;
      margin-bottom: 16px;

      @media(max-width: 960px) {
        font-size: 72px;
      }
    }

    .promo__bg {
      img {
        object-position: center bottom;
      }

      &:after {
        opacity: 0.2;
      }
    }
  }

  &--clients {
    height: 100vh;
    padding: 100px 0 120px 0;

    @include screen-down('lg') {
      height: auto;
      min-height: 100%;
      padding: 100px 0 50px 0;
    }

    .promo-container {
      position: relative;
      display: table;
      width: 100%;
      height: 100%;
    }

    .promo-title {
      @include screen-down('lg') {
        font-size: 42px;
        line-height: 48px;
      }
    }

    .promo-info {
      display: table-cell;
      vertical-align: middle;
      padding-top: 75px;
    }

    .promo__bg {
      &:after {
        opacity: 0.6;
      }
    }

    .promo-bottom-controls {
      display: block;

      @include screen-down('lg') {
        display: none;
      }
    }

    .promo-bottom-controls-arrow-btn {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -30px;
      margin-top: -25px;
      width: 60px;
      height: 60px;
      border: 1px solid #fff;
      background: none;
      border-radius: 50%;
      cursor: pointer;
      padding: 0;
      opacity: 0.4;
      -webkit-transition: .2s;
      transition: .2s;
    }

    .promo-bottom-controls-arrow-btn__arrow {
      position: absolute;
      width: 29px;
      height: 16px;
      top: 50%;
      left: 50%;
      margin-left: -14px;
      margin-top: -7px;
      background: url('~assets/img/arrow-down-small.png') no-repeat 0 0;
      animation: promo-arrow-animation 1.4s linear infinite;
    }
  }

  &--about-service {
    max-height: 650px;

    @media (max-width: 1023px) {
      padding: 170px 10px 0;
    }

    .promo {
      &-container {
        @media (max-width: 991px) {
          padding: 0;
        }
      }

      &__bg::after {
        background: linear-gradient(70.17deg, #20009E 0%, rgba(40, 0, 199, 0) 100%);
        opacity: 0.5;
      }

      &-info {
        padding-top: 0;
      }
    }
  }

  &-bottom-controls {
    display: none;
    position: absolute;
    left: 0;
    bottom: 35px;
    width: 100%;
  }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #000;
      opacity: 0.3;
    }
  }

  &-container {
    width: 1010px;
    margin: 0 auto;
    padding: 0;
    padding-top: 25px;

    @media (max-width: 1290px) {
      padding: 25px 60px 0 60px;
      width: auto;
    }

    @media (max-width: 1024px) {
      padding: 25px 30px 0 30px;
    }
  }

  &-info {
    position: relative;
    padding-top: 50px;
  }

  &-title {
    font-family: "AktivGroteskCorp", "Arial", sans-serif;
    margin: 0 auto 87px;
    max-width: 1000px;
    font-size: 54px;
    line-height: 61px;
    font-weight: 300;

    @media (max-width: 959px) {
      font-size: 48px;
    }
  }

  &.error-page {
    padding-top: 150px;
    display: flex;
    flex-direction: column;

    .promo-container {
      padding-top: 0;
      flex-grow: 1;
    }

    .promo-info {
      padding-top: 0;
    }

    .promo-title {
      font-family: "AktivGroteskCorp", "Arial", sans-serif;
      margin: 0 auto 50px auto;
      max-width: 1000px;
      font-size: 420px;
      line-height: 1;
      font-weight: 700;
      color: $color-white;
      opacity: 0.8;
      text-align: center;

      @include screen-down('lg') {
        font-size: 300px;
        font-weight: 300;
      }
    }
  }
}

@keyframes promo-arrow-animation {

  25% {
    -webkit-transform: translate(0, 4px);
    transform: translate(0, 4px);
  }

  75% {
    -webkit-transform: translate(0, -4px);
    transform: translate(0, -4px);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
</style>
