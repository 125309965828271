<template>
  <SidebarMobile @closeSidebar="closeSidebar" @openPopup="openPopup" />
</template>
<script>
const SidebarMobile = () => import(
  /* webpackChunkName: "SidebarMobile" */
  '~/components/Mobile/Common/SidebarMobile.vue'
);
export default {
  name: 'LazySidebarDesktop',
  components: {
    SidebarMobile,
  },
  methods: {
    closeSidebar() {
      this.$emit('closeSidebar');
    },
    openPopup() {
      this.$emit('openPopup');
    }
  },
}
</script>
