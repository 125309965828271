import mitt from 'mitt';

export default function(context, inject) {
    const emitter = mitt();
    inject('eventBus', {
        $emit: (...args) => emitter.emit(...args),
        _$on: (...args) => emitter.on(...args),
        _$off: (...args) => emitter.off(...args),
    });
};
