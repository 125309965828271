<template>
  <a href="https://mts-link.ru/spec-offer/messenger/ " class="header-banner" target="_blank">
    <div class="header-banner__content">
      <div class="header-banner__content_text">
        Быстрый переход из&nbsp;Slack в&nbsp;МТС&nbsp;Линк Чаты
      </div>
      <VIconMTS
        :name="'mts/icon-mts--arrow-link'"
        width="24"
        height="24"
        class="header-banner__content_icon"
      />
    </div>
  </a>
</template>
<script>
import VIconMTS from '~/components/Common/MTS/VIconMTS.vue'
export default {
  name: 'HeaderBannerMobile',
  components: { VIconMTS },
}
</script>
<style lang="scss" scoped>
.header-banner {
  display: block;
  position: relative;
  top: 0;
  padding: 0 10px;
  background: $color-lavander;
  text-decoration: none;
  font-family: $font-mts-wide;
  color: #1d2023;
  transition: 0.3s;
  z-index: 10000;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    min-height: 68px;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-align: left;
    text-transform: uppercase;

    &_text {
      max-width: 250px;
    }

    &_icon {
      display: inline-block;
    }
  }
}
</style>
