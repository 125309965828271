<template>
  <div>
    <template v-if="!statusForm">
      <form
        :id="formName"
        :name="formName"
        :data-title-success="dataTitleSuccess"
        :data-message-success="dataMessageSuccess"
        action=""
        class="white-color-autofill"
        :class="[modifier, { 'loading': isLoading === true }]"
        :data-status="formStatus"
        @submit="onSubmit"
      >

        <input id="fio_webinar" type="hidden" name="fio">
        <input id="google_cid_webinar" type="hidden" name="google_cid">
        <input id="locale_webinar" type="hidden" name="locale" value="ru">
        <div v-if="abFormSelectorVariant > 0">
          <input v-for="(value, name) in abTestHiddenFields" :key="name" :name="name" :value="value" hidden>
        </div>

        <VInput
          id="name"
          v-model="form.firstname"
          name="name"
          label="Имя"
          autocomplete="name"
          type="text"
          :error="getErrors('firstname')"
        />

        <VInput
          id="last_name"
          v-model="form.lastname"
          name="last_name"
          label="Фамилия"
          autocomplete="family-name"
          type="text"
          :error="getErrors('lastname')"
        />

        <VInput
          id="email"
          v-model="form.email"
          name="email"
          label="Email"
          autocomplete="email"
          type="text"
          :error="getErrors('email')"
        />

        <VPhoneInput
          id="phone"
          v-model="form.phone"
          name="phone"
          label="Телефон"
          :modifier="[modifier ? modifier : 'long-error']"
          :error="getErrors('phone')"
        />

        <VInput
          v-if="modifier !== 'manual'"
          id="password"
          v-model="form.password"
          type="password"
          name="password"
          label="Придумайте пароль"
          autocomplete="new-password"
          :error="getErrors('password')"
          :is-password="true"
        />

        <VDropdown
          id="purpose_of_use"
          v-model="form.purpose_of_use"
          name="purpose_of_use"
          label="Цель использования сервиса?"
          :options="purpose_of_use"
          :error="getErrors('purpose_of_use')"
        />

        <VDropdown
          v-if="isShowNumberDropdown"
          id="number_of_employees"
          v-model="form.number_of_employees_select"
          name="number_of_employees_select"
          label="Сколько сотрудников в компании"
          :options="number_of_employees"
          :error="getErrors('number_of_employees_select')"
        />

        <input v-model="numberOfEmployees" name="number_of_employees" hidden>

        <div class="form-btn__wrapper">
          <VButton type="submit" :color="buttonColor" class="form-btn">
            {{ buttonText }}
          </VButton>
        </div>

        <div class="order-form__submit">
          <div class="order-form__dop-info">
            Нажимая на кнопку «{{ buttonText }}» , вы акцептуете условия
            <a target="_blank" href="/legal/license-agreement.pdf" class="form-link">оферты</a>,
            <a target="_blank" href="/legal/private-agreement-soft.pdf" class="form-link">соглашаетесь на обработку
              персональных данных</a>
            и связь с вами способами, указанными в оферте, в целях исполнения условий оферты.
          </div>
        </div>
      </form>
    </template>
    <template v-else>
      <FormMessage
        :modifier="'registration'"
        :title="titleMessage"
        :message="textMessage"></FormMessage>
    </template>
  </div>
</template>

<script>
import SubmitGatewayMixin from "@/mixins/SubmitGatewayMixin";
import EventBus from "@/mixins/EventBus";
import FormOptions from "@/mixins/FormOptions";
import ValidationFields from "@/mixins/ValidationFields";
import VInput from "~/components/Common/VInput.vue";
import VPhoneInput from "~/components/Common/VPhoneInput.vue";
import VDropdown from "~/components/Common/VDropdown.vue";
import VButton from "~/components/Common/VButton.vue";
import FormMessage from "~/components/Common/Form/FormMessage.vue";
import ABTests from "~/helpers/ABTests";


export default {
  name: 'WebinarRegistrationForm',
  components: {FormMessage, VButton, VDropdown, VPhoneInput, VInput},
  mixins: [FormOptions, ValidationFields, SubmitGatewayMixin, EventBus],
  props: {
    modifier: {
      type: String,
    },
    formName: {
      type: String,
      default: 'registration'
    },
    dataTitleSuccess: {
      type: String,
      default: ''
    },
    dataMessageSuccess: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: 'Зарегистрироваться',
    },
    buttonColor: {
      type: String,
      default: 'white',
    },
    isABTestEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        purpose_of_use: '',
        number_of_employees_select: '',
      },
      subscription: false,
      abFormSelectorVariant: '0',
      abTestHiddenFields: {},
      isShowNumberDropdown: false,
    };
  },
  computed: {
    purposeDropdown() {
      return this.form.purpose_of_use;
    },
    numberOfEmployees() {
      return this.form.purpose_of_use === 'work'
        ? this.form.number_of_employees_select
        : this.form.purpose_of_use
    },
  },
  watch: {
    purposeDropdown() {
      this.isShowNumberDropdown = this.purposeDropdown === 'work';
    }
  },
  mounted() {
    this.abTestHiddenFields = ABTests.getABTestHiddenFields();
    this.abFormSelectorVariant = ABTests.getABFormSelectorVariant()
  }
};
</script>
<style lang="scss" scoped>
.form-btn {
  min-width: 250px;
  margin: 20px auto 20px;

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 479px) {
    font-size: 11px;
  }
}

.form-link {
  color: #fff;
}

.loading .form-btn {
  background-image: url("~assets/img/loader.svg");
  color: transparent !important;
  background-size: 15%;
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: none;
}

::v-deep .form-control {
  &__select {
    &:after {
      display: none;
    }

    &-label {
      transform: translateY(0) !important;
    }

    &-body {
      appearance: auto !important;
      padding: 0;
      margin-top: 7px;
    }
  }

  &__show-password {
    bottom: 10px;
  }
}

</style>
