<template>
  <footer class="footer" :class="{ '--with-border' : showLine }">
    <div class="container">
      <div class="footer__top">
        <div class="footer__top-left">
          <h4 class="footer__top-left-title">{{ title }}</h4>
          <div class="footer__top-left-description">{{ description }}</div>
        </div>

        <div class="footer__top-right">
          <FooterMenuDesktop v-if="menu.length" :menu="menu" />
        </div>
      </div>

      <div class="footer__middle">
        <div class="footer__middle-wrapper">
          <SocialLinksBlock
            :icon="responsiveIcon"
            :social-list="socialList"
          />

          <a
            class="footer__middle-download"
            href="/application/"
            rel="noreferrer nofollow noopener"
            target="_blank"
          >
            <VIconMTS class="footer__middle-download-icon" name="mts-brand" />

            <div>
              <div class="footer__middle-download-title">МТС Линк</div>

              <div class="footer__middle-download-text">Скачать</div>
            </div>
          </a>
        </div>

        <div class="footer__middle-links">
          <a
            v-for="(item, $index) in links"
            :key="$index"
            :href="item.href"
            :rel="item.rel"
            :target="item.target"
            class="footer__middle-links-item"
          >
            {{ item.title }}
          </a>
        </div>
      </div>

      <div class="footer__bottom" v-html="bottom" />
    </div>
  </footer>
</template>

<script>
import VIconMTS from '@/components/Common/MTS/VIconMTS'
import FooterMenuDesktop from '@/components/Desktop/Footer/FooterMenuDesktop'
import SocialLinksBlock from '@/components/Common/SocialLinksBlockMTS'
import FooterMixin from '@/components/Desktop/Footer/mixins/FooterMixin'

export default {
  name: 'FooterDesktop',
  components: {
    SocialLinksBlock,
    FooterMenuDesktop,
    VIconMTS,
  },
  mixins: [FooterMixin],
}
</script>

<style lang="scss" scoped>
.footer {
  background-color: $color-gray-2;
  padding: 24px 0;
  font-family: $font-mts-compact;

  & .container {
    max-width: 1400px;
    padding: 0 20px;
  }

  &.--with-border {
    border-top: 1px solid rgba(188, 195, 208, 0.5);
  }

  &__top,
  &__middle {
    display: grid;
    grid-template-columns: 1fr 548px;
    column-gap: 32px;
    margin-bottom: 48px;

    @include screen-down('lg') {
      grid-template-columns: 1fr 368px;
    }

    &-left {
      &-title {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
        color: $color-dark-2;
        margin-bottom: 8px;

        @include screen-down('lg') {
          font-size: 17px;
          line-height: 24px;
        }
      }

      &-description {
        font-size: 17px;
        line-height: 24px;
        font-weight: 400;
        max-width: 664px;
        color: $color-dark-2;

        @include screen-down('lg') {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  &__middle {
    &-wrapper {
      display: grid;
      grid-template-columns: 44% 46%;
      column-gap: 32px;

      @include screen-down('lg') {
        grid-template-columns: 1fr;
        row-gap: 24px;
      }
    }

    &-download {
      display: grid;
      grid-template-columns: 44px 1fr;
      column-gap: 12px;
      color: inherit;
      text-decoration: none;

      @include screen-down('lg') {
        grid-template-columns: 32px 1fr;
      }

      ::v-deep {
        .social__links {
          width: 44px;
          height: 44px;

          @include screen-down('lg') {
            width: 32px;
            height: 32px;
          }
        }
      }

      &-icon {
        width: 44px;
        height: 44px;

        @include screen-down('lg') {
          width: 32px;
          height: 32px;
        }
      }

      &-title {
        font-size: 17px;
        line-height: 24px;
        color: $color-dark-2;

        @include screen-down('lg') {
          font-size: 14px;
          line-height: 20px;
        }
      }

      &-text {
        font-size: 14px;
        line-height: 20px;
        color: $color-gray-1;
      }
    }

    &-links {
      display: grid;
      grid-template-columns: 211px 175px 85px;
      gap: 16px 24px;

      @include screen-down('lg') {
        grid-template-columns: max-content 1fr;
        grid-template-rows: repeat(3, 1fr);
        gap: 12px;
      }

      &-item {
        color: $color-gray-1;
        text-decoration: none;
        font-size: 17px;
        line-height: 24px;
        text-decoration: none;

        @include screen-down('lg') {
          font-size: 14px;
          line-height: 17px;
        }

        &:first-child {
          grid-column: span 3;

          @include screen-down('lg') {
            grid-column: span 2;
          }
        }

        &:nth-child(2) {
          @include screen-down('lg') {
            grid-column: span 2;
          }
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__bottom {
    font-size: 17px;
    line-height: 24px;
    color: $color-gray-1;
    max-width: 591px;

    @include screen-down('lg') {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
