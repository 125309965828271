import clickOutside from "@/directives/clickOutside";

export default {
  directives: { clickOutside },
  data() {
    return {
      mobileWidth: false,
      showMenu: false,
    }
  },
  computed: {
    bottomBreadcrumbs() {
      if (this.$route.path.includes('/bill/success/')) {
        return 'Успешная оплата счета'
      } else if (this.$route.path.includes('/bill/fail/')) {
        return 'Неудачная оплата счета'
      } else if (this.$route.path === '/bill/') {
        return 'Оплата счета'
      }
      return 'Методичка по организации и проведению вебинаров'
    },
    currentYear() {
      return new Date().getFullYear()
    },
  },
  created() {
    if (process.browser) {
      this.onWindowResize()
      // eslint-disable-next-line nuxt/no-globals-in-created
      window.addEventListener('resize', this.onWindowResize)
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.onWindowResize)
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    onWindowResize() {
      this.mobileWidth = window.matchMedia('(max-width: 576px)').matches
    },
    closeMenu() {
      this.showMenu = false
    },
  },
}
