<template>
  <FooterMobile />
</template>
<script>
const FooterMobile = () => import(
    /* webpackChunkName: "FooterMobile" */
    '~/components/Mobile/Footer/FooterMobile.vue'
  );
export default {
  name: 'LazyFooterMobile',
  components: {
    FooterMobile,
  }
}
</script>
