export default {
  created() {
    if (this.__onFormResult) this.$eventBus._$on('form-result', this.__onFormResult);
    if (this.__onShowFormSidebar) this.$eventBus._$on('show-sidebar', this.__onShowFormSidebar);
    if (this.__onServerErrorReceived) this.$eventBus._$on('form-server-errors', this.__onServerErrorReceived);
    if (this.__onIntercomOpen) this.$eventBus._$on('open-intercom', this.__onIntercomOpen);
  },
  destroyed() {
    if (this.__onFormResult) this.$eventBus._$off('form-result', this.__onFormResult);
    if (this.__onShowFormSidebar) this.$eventBus._$off('show-sidebar', this.__onShowFormSidebar);
    if (this.__onServerErrorReceived) this.$eventBus._$off('form-server-errors', this.__onServerErrorReceived);
    if (this.__onIntercomOpen) this.$eventBus._$off('open-intercom', this.__onIntercomOpen);
  },
};
