// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/icons/sucsses-icon.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/img/icons/arrow-back.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/img/icons/popup-sent.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-success-message[data-v-9bcae92a]{font-family:\"AktivGroteskCorp\",\"Arial\",sans-serif;display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center;transition:.4s;padding-top:120px;position:relative;color:#fff;font-weight:300}.form-success-message__title[data-v-9bcae92a]{font-size:25px;margin-bottom:20px}.form-success-message__text[data-v-9bcae92a]{font-size:18px;opacity:.6;line-height:1.7}.billing .form-success-message[data-v-9bcae92a]:not(.success-error):before{content:\"\";position:absolute;top:0;left:50%;margin-left:-52px;width:103px;height:85px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0}.billing .form-success-message__title[data-v-9bcae92a]{color:#ffb300}.billing .form-success-message__back-link[data-v-9bcae92a]{color:#fff;font-size:18px;position:relative;text-decoration:none;cursor:pointer;margin-top:25px}.billing .form-success-message__back-link[data-v-9bcae92a]:before{transform:rotate(180deg);content:\"\";position:absolute;left:-17px;top:5px;width:11px;height:11px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 0 0}.popup .form-success-message__title[data-v-9bcae92a]:before{content:\"\";position:absolute;top:-35px;left:50%;transform:translateX(-50%);width:228px;height:228px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat 0 0}.popup .form-success-message__title[data-v-9bcae92a]{font-style:normal;font-weight:400;font-size:16px;line-height:32px;color:#3d3d3d;margin:0 0 5px;padding-top:80px}.popup .form-success-message__text[data-v-9bcae92a]{max-width:none;margin:0 auto 8px;font-style:normal;font-weight:400;font-size:14px;line-height:18px;color:#707070}.request[data-v-9bcae92a]{color:#333}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
