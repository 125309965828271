export default {
  data() {
    return {
      purpose_of_use_short: [
        {
          label: 'Ищу решение для компании',
          value: 'work',
          disabled: false,
        },
        {
          label: 'Ищу решения для личных целей',
          value: 'personal_use',
          disabled: false,
        },
      ],
      purpose_of_use_student: [
        {
          label: 'Ищу решение для компании',
          value: 'work',
          disabled: false,
        },
        {
          label: 'Ищу решения для личных целей',
          value: 'personal_use',
          disabled: false,
        },
        {
          label: 'Я студент, использую сервис для учебы',
          value: 'student',
          disabled: false,
        },
      ],
      purpose_of_use: [
        {
          label: 'Решение для компании',
          value: 'work',
          disabled: false,
        },
        {
          label: 'Для личных целей',
          value: 'personal_use',
          disabled: false,
        },
        {
          label: 'Иду на мероприятие',
          value: 'participant',
          disabled: false,
        },
      ],
      number_of_employees: [
        {
          label: '1-20',
          value: '1_20',
          disabled: false,
        },
        {
          label: '21-100',
          value: '21_100',
          disabled: false,
        },
        {
          label: '101-500',
          value: '101_500',
          disabled: false,
        },
        {
          label: '500+',
          value: '500_plus',
          disabled: false,
        },
      ],
      user_role: [
        {
          label: 'Руководитель отдела',
          value: 'Руководитель отдела',
          disabled: false,
        },
        {
          label: 'Директор',
          value: 'Директор',
          disabled: false,
        },
        {
          label: 'Сотрудник отдела',
          value: 'Сотрудник отдела',
          disabled: false,
        },
        {
          label: 'Физическое лицо',
          value: 'Физическое лицо',
          disabled: false,
        },
        {
          label: 'Владелец бизнеса',
          value: 'Владелец бизнеса',
          disabled: false,
        },
        {
          label: 'Студент',
          value: 'Студент',
          disabled: false,
        },
      ],
    }
  },
};
