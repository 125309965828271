<template>
  <div class="wrapper">
    <HeaderDemoMobile/>
    <section class="base-content">
      <Nuxt/>
    </section>
      <FooterEmptyMobile v-if="showEmptyFooter"/>
      <FooterMobile v-if="showFooter"/>
      <ExternalIntegrations/>
    <!--    <LazyHydrate never :trigger-hydration="isOpenFormSidebar">-->
    <Transition name="slide">
      <LazyFormSidebar
          v-if="isOpenFormSidebar"
          :form="form"
          :header="header"
          :is-a-b-test-enabled="isABTestEnabled"
          :form-name="formName"
          @closeFormSidebar="closeFormSidebar"
      />
    </Transition>
    <!--    </LazyHydrate>-->
    <DataCollection/>
    <div class="popmechanic-disable-flag" :class="{true: hidePopmechanic}"></div>
  </div>
</template>
<script>
import { initPageData, isNoindexNofollow } from "@/helpers/pageData";
import HeaderDemoMobile from "~/components/Mobile/Header/HeaderDemoMobile.vue";
import FooterEmptyMobile from "~/components/Mobile/Footer/FooterEmptyMobile.vue";
import FooterMobile from "~/components/Mobile/Footer/FooterMobile.vue";
import ExternalIntegrations from "~/components/Common/ExternalIntegrations/ExternalIntegrations.vue";
import DataCollection from "~/components/Common/DataCollection.vue";
import LazyFormSidebar from "~/components/Common/Form/Lazy/LazyFormSidebar.vue";
import EventBus from "~/mixins/EventBus";

export default {
  name: 'inner',
  components: {
    DataCollection,
    ExternalIntegrations,
    FooterEmptyMobile,
    HeaderDemoMobile,
    FooterMobile,
    LazyFormSidebar,
  },
  mixins: [EventBus],
  data() {
    return {
      isOpenSidebar: false,
      isOpenFormSidebar: false,
      form: '',
      header: '',
      isShowCreateMeeting: false,
      isAutotest: false,
      isABTestEnabled: false,
      formName: undefined,
    }
  },
  head() {
    return {
      __dangerouslyDisableSanitizers: ['script'],
      script: [
        {innerHTML: JSON.stringify(this.generateLdPlusJSON()), type: 'application/ld+json'}
      ],
      link: [
        {
          rel: 'canonical',
          href: 'https://mts-link.ru' + this.$route.path,
        },
      ],
      meta: [
        ... isNoindexNofollow(this.$route.path) ? [{ name: 'robots', content: 'noindex, nofollow'}] : [],
      ],
    }
  },
  computed: {
    showEmptyFooter() {
      const route = this.$route.name
      return route === 'metodichka' || route.includes('/tariffs') || route.includes('/billing-registration-custom') || this.$route.path === "/bill/fail/" || this.$route.path === "/bill/success/" || this.$route.path === "/bill/";
    },
    showFooter() {
      return  this.$route.path === "/partners/";
    },
    hidePopmechanic() {
      const route = this.$route.name
      return route.includes('/tariffs') || route.includes('/billing-registration-custom') || route.includes('bill-item') || this.$route.path === "/bill/";
    },
  },
  mounted() {
    initPageData();
  },
  methods: {
    openSidebar() {
      this.isOpenSidebar = true;
    },
    closeSidebar() {
      this.isOpenSidebar = false;
    },
    __onShowFormSidebar(params) {
      this.isOpenFormSidebar = true;
      this.form = params.form
      this.header = params.header
      this.isABTestEnabled = params?.params?.isABTestEnabled
      this.formName = params?.params?.formName
    },
    closeFormSidebar() {
      this.isOpenFormSidebar = false;
    },
    generateLdPlusJSON() {
      return {
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "МТС Линк",
        "alternateName": "МТС Линк",
        "url": "https://mts-link.ru" + this.$route.path,
        "logo": "https://mts-link.ru/_nuxt/img/mts-link.svg"
      };
    },
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 499;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;

  &--header-dropdown {
    background-color: transparent;
    transition: none;
  }

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

.base-content {
  position: relative;
}

.v-enter {
  opacity: 0;
}

.v-leave-to {
  opacity: 0;
  visibility: hidden;
}

.slide-enter-active {
  transform: translateX(100%);
  transition: all .2s ease-out;
}

.slide-leave-active {
  transition: all .2s ease-out;
}

.slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
</style>
