<template>
  <header class="header-demo">
    <div class="header-demo__inner">
      <a href="/" class="header-demo__logo_link">
        <img
          class="header-demo__logo_img"
          src="~/assets/img/main/logo--mts-icon-full.svg"
          alt="logo"
          width="68"
          height="26"
        />
      </a>
    </div>
  </header>
</template>

<script>
export default {
  name: 'MTSHeaderDemoDesktop',
}
</script>

<style lang="scss" scoped>
.header-demo {
  position: absolute;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 100;
  min-width: 336px;

  &__inner {
    padding-left: 25px;
    padding-right: 25px;
  }
  &_logo_link {
    display: block;
  }
}

</style>
