<template>
  <div id="header-panel" class="header-panel">
    <!-- временно скрытый прогресс-бар для статей -->

    <!-- <div v-if="isProgressBarShown" ref="progress" class="progress-bar"></div> -->
    <!-- <div class="header-panel__bottom"> -->
    <div class="container">
      <div class="header-panel__inner">
        <a href="/" class="header-panel__logo">
          <img src="~/assets/img/main/logo--mts.svg" alt="Webinar" loading="lazy" width="103" height="40">
        </a>

        <VButtonLinkMTS href="javascript:void(0);" class="header-panel__btn --s --grey"
          @click="sendMetricsSigninClient">
          Войти
        </VButtonLinkMTS>
        <HeaderMenuListMobile @openSidebar="$emit('openSidebar')" />
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import HeaderPanelMixin from "~/components/Desktop/Header/mixins/HeaderPanelMixin";
import HeaderMenuListMobile from "~/components/Mobile/Header/HeaderMenuListMobile"
import VIconMTS from '~/components/Common/MTS/VIconMTS.vue';
import VButtonLinkMTS from "~/components/Common/MTS/VButtonLinkMTS.vue";

export default {
  name: 'HeaderPanelMobile',
  components: { VIconMTS, VButtonLinkMTS, HeaderMenuListMobile },
  mixins: [HeaderPanelMixin],
}
</script>

<style lang="scss" scoped>
.header-panel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-50vh);
  transition: all .3s ease;
  background: #fff;
  border-bottom: 1px solid rgba(150, 159, 168, 0.2);
  font-family: 'MTS Compact', Arial, sans-serif;
  z-index: 449;

  .container {
    padding: 8px 20px;
  }

  &.visible {
    transform: translateY(0);
  }

  &__inner {
    position: relative;
    z-index: 30;
    display: flex;
    align-items: center;
  }

  &__logo {
    display: flex;
    text-decoration: none;
    padding: 0;
    margin-right: 44px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__btn {
    margin: 0 16px 0 auto;
  }
}

// .progress-bar {
//   height: 5px;
//   background: #262626;
// }</style>
