// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/personaldemo/arrow-back.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-demo[data-v-1a2985fe]{position:absolute;top:0;left:0;right:0;color:#fff;z-index:100;min-width:336px}.header-demo._compact.--webinar-group[data-v-1a2985fe]{max-width:100%;text-align:center;padding:32px 10px 16px}.header-demo._compact.--webinar-group-bottom[data-v-1a2985fe]{padding-top:0}.header-demo._compact.--webinar-group .header-demo-inner[data-v-1a2985fe]{padding-left:0;padding-right:0}.header-demo-bottom[data-v-1a2985fe],.header-demo._compact[data-v-1a2985fe]{padding-top:15px}.header-demo-inner[data-v-1a2985fe]{padding-left:25px;padding-right:25px}.header-demo-back-link[data-v-1a2985fe]{position:absolute;font-size:18px;color:#fff;right:45px;top:48px;text-decoration:none;font-weight:300}.header-demo-back-link[data-v-1a2985fe]:before{content:\"\";position:absolute;right:-17px;top:3px;width:11px;height:11px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0}.header-demo-back-link[data-v-1a2985fe]:hover{border-bottom:1px solid hsla(0,0%,100%,.3)}@media(max-width:479px){.header-demo-back-link[data-v-1a2985fe]{right:30px}}@media(max-width:330px){.header-demo-back-link[data-v-1a2985fe]{right:40px}}.logo[data-v-1a2985fe]{transition:.2s}.logo-link[data-v-1a2985fe]{display:block}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
